


















































import { Component, Prop, Vue } from "vue-property-decorator";
import { TBatchDetail } from "../pages/batchType";
import { TranslationMapping } from "@/grid/gridTypes";
import { batchStatusMapping } from "@/utils/BatchUtils";
import { formatDateTime, getFormattedCurrency } from "@/utils/CommonUtils";

@Component({})
export default class BatchDetail extends Vue {
	@Prop() readonly batchDetail!: TBatchDetail | null;
	private statusStyle = "";

	get status() {
		const translationMappings: TranslationMapping[] = batchStatusMapping();
		const foundMapping = translationMappings.find(
			(element: TranslationMapping) => {
				return this.batchDetail?.status === element.value;
			}
		);
		if (foundMapping) {
			if (foundMapping.style) {
				this.statusStyle = foundMapping.style;
			}
			return foundMapping.translatedValue;
		}
		return "N/A";
	}

	get statusDate() {
		if (this.batchDetail) {
			return formatDateTime(this.batchDetail?.statusDate);
		}
		return null;
	}

	get totalAmount() {
		if (this.batchDetail?.totalAmount) {
			return getFormattedCurrency(String(this.batchDetail?.totalAmount));
		}
		return null;
	}
	get fileName() {
		return this.batchDetail?.fileName ?? "N/A";
	}

	get id() {
		return this.batchDetail?.id;
	}

	get isReady() {
		return this.batchDetail !== null;
	}
}
