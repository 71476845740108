












import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import {
	CellClassParams,
	ColDef,
	ColGroupDef,
	ICellRendererParamsTyped,
	ValueGetterParamsTyped,
} from "ag-grid-community";

import Grid from "@/grid/Grid.vue";
import { stagingStatusMapping } from "@/utils/BatchUtils";
import { columnDescriptionDateFormatter } from "@/utils/CommonUtils";
import {
	FinalBatchReviewUpdateRecord,
	UpdateType,
} from "@/models/FinalBatchReviewRecord";

@Component({
	components: {
		Grid,
	},
})
export default class BatchUpdates extends Vue {
	/**
	 * null is loading data while [] is no data.
	 */
	@Prop({
		type: Array,
		default: null,
	})
	private rowData!: FinalBatchReviewUpdateRecord[] | null;

	static readonly ROW_DATA_UPDATED_EVENT = "row-data-updated";

	readonly lastUpdatedByCellClass = (cellClassParams: CellClassParams) => {
		const rowData = cellClassParams.data as FinalBatchReviewUpdateRecord;
		return [
			"batch-updates-row__updated-by",
			rowData.updatedByEmployer
				? ""
				: "batch-updates-row__updated-by--not-employer",
		];
	};

	readonly typeIconCellRenderer = (
		params: ICellRendererParamsTyped<FinalBatchReviewUpdateRecord>
	) => {
		return params.data.updateType === UpdateType.W
			? `<i class="fas fa-exclamation batch-updates-row__update-icon"></i>`
			: "";
	};

	readonly typeValueGetter = (
		params: ValueGetterParamsTyped<FinalBatchReviewUpdateRecord>
	) => (params.data.updateType === UpdateType.I ? "I" : "W");

	readonly columnDefs: (ColGroupDef | ColDef)[] = [
		{
			headerName: "",
			field: "typeIcon",
			width: 10,
			cellRenderer: this.typeIconCellRenderer,
		},
		{
			headerName: "Type",
			field: "updateType",
			valueGetter: this.typeValueGetter,
			width: 10,
			cellClass: "update-type",
		},
		{ headerName: "Entity", field: "entityType", minWidth: 115 },
		{ headerName: "Entity ID", field: "entityId", minWidth: 150 },
		{ headerName: "Entity name", field: "entityName", minWidth: 185 },
		{
			headerName: "Description",
			field: "description",
			minWidth: 400,
			width: 500,
			valueFormatter: columnDescriptionDateFormatter,
		},
		{
			headerName: "Status",
			field: "status",
			cellRendererParams: {
				translationMappings: stagingStatusMapping(),
			},
			cellRenderer: "valueTranslatedCellRenderer",
			minWidth: 75,
			width: 150,
		},
		{
			headerName: "Updated by",
			field: "lastUpdatedBy",
			minWidth: 260,
			cellClass: this.lastUpdatedByCellClass,
		},
		{ field: "updatedByEmployer", hide: true },
	];
}
