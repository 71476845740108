











































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import EmployerSelector from "@/components/EmployerSelector.vue";
import axios, { axiosStatic } from "@/utils/ApiUtils";
import {
	portalRolesListURL,
	portalUserListURL,
} from "@/constants/apiconstants";
import { parseErrorMessage } from "@/utils/ErrorUtils";
import LeftRightFooter from "@/components/LeftRightFooter.vue";
import Container from "@/components/Container.vue";
import Button from "@/form/Button.vue";
import { hasPermission } from "@/utils/PermissionUtils";
import { toastErrorMessage } from "@/plugins/toasts";
import { TSelectLevel } from "@/components/employerSelectorTypes";
import Grid from "@/grid/Grid.vue";
import { AccountStatus, FilterModel, PagedResult } from "@/grid/gridTypes";
import {
	ColDef,
	ColGroupDef,
	ICellRendererParamsTyped,
	IServerSideDatasourceTyped,
	IServerSideGetRowsParamsTyped,
} from "ag-grid-community";
import { columnDateTimeFormatter } from "@/utils/CommonUtils";
import { PortalUser } from "@/models/PortalUser";
import GridActionsRenderer from "@/grid/GridActionsRenderer.vue";
import { isOnlyOneChildlessEmployerSelected } from "@/utils/EmployerSelectorUtils";
import { NO_RC_ERROR_MESSAGE } from "@/constants/constants";
import { EmployerHierarchy } from "@/store/modules/persistent/persistentTypes";
import { createNamespacedHelpers } from "vuex";
import GridFilter from "@/components/GridFilter.vue";
import TextField from "@/form/TextField.vue";
import SelectField from "@/form/SelectField.vue";
import { accountStatus } from "@/constants/pageConstants";
import { RoleOption } from "@/pages/maintenanceTypes";
import MultiSelect from "@/form/MultiSelect.vue";

import {
	isDomainUser,
	isSponsorUser,
} from "@/pages/maintenance/PortalUserUtils";
import { downloadFile } from "@/utils/DownloadUtils";
import moment from "moment";
import { commitToModule, registerModule } from "@/store/modules/filters";
const { mapState } = createNamespacedHelpers("persistent");

@Component({
	components: {
		MultiSelect,
		TextField,
		EmployerSelector,
		Layout,
		LeftRightFooter,
		Container,
		Button,
		Grid,
		GridFilter,
		SelectField,
	},
	computed: mapState(["selectedEntities", "employerHierarchy"]),
})
export default class PortalUserListPage
	extends Vue
	implements IServerSideDatasourceTyped
{
	/**
	 * Type the mapped persistent.selectedEntities getter.
	 * It is a computed property. Do not mutate it.
	 */
	selectedEntities!: string[];

	statusOptions = accountStatus;

	/**
	 * Type the mapped persistent.employerHierarchy getter.
	 * It is a computed property. Do not mutate it.
	 */
	employerHierarchy!: EmployerHierarchy[];
	private pageContext: TSelectLevel = "ALL";
	private errorMessage: string | null = null;
	private gridReady = false;
	private EDIT_USERS = "EDIT_USERS";
	private VIEW_USERS = "VIEW_USERS";
	private EDIT_DOMAIN_USERS = "EDIT_DOMAIN_USERS";
	private EDIT_SPONSOR_USERS = "EDIT_SPONSOR_USERS";
	private vuexStore = "portalUserPage";

	public $refs!: {
		gridEl: Grid;
	};
	private selectedRole: RoleOption[] = [];

	private filterModel: FilterModel = {
		status: {
			value: AccountStatus.ACTIVE,
			column: "status",
		},
		name: {
			value: "",
			column: "name",
		},
		email: {
			value: "",
			column: "email",
		},
		role: {
			value: "",
			column: "role",
		},
	};

	private readonly columnDefs: (ColGroupDef | ColDef)[] = [
		{
			headerName: "Title",
			field: "title",
			minWidth: 20,
			resizable: true,
		},
		{
			headerName: "Name",
			field: "name",
			minWidth: 300,
			resizable: true,
		},
		{
			headerName: "Position",
			field: "position",
			minWidth: 180,
			resizable: true,
		},
		{
			headerName: "Email",
			field: "email",
			minWidth: 300,
			resizable: true,
		},
		{
			headerName: "Mobile phone",
			field: "mobilePhone",
			minWidth: 160,
			resizable: true,
		},
		{
			headerName: "Last login",
			field: "lastLogin",
			minWidth: 210,
			resizable: true,
			valueFormatter: columnDateTimeFormatter,
		},
		{
			headerName: "Status",
			field: "suspend",
			minWidth: 20,
			resizable: true,
			cellRendererParams: {
				translationMappings: [
					{
						value: false,
						translatedValue: "Active",
						style: "font-weight: bold;color:#228b22;", //Forest green
					},
					{
						value: true,
						translatedValue: "Inactive",
						style: "font-weight: bold;color:#8b0000;", //Red
					},
				],
			},
			cellRenderer: "valueTranslatedCellRenderer",
		},
	];
	private roleOptions: RoleOption[] = [];

	beforeMount() {
		const canEdit = this.hasPermission(this.EDIT_USERS);
		const canView = this.hasPermission(this.VIEW_USERS);
		if (canEdit || canView) {
			this.columnDefs.push({
				headerName: canEdit ? "Edit" : "View",
				cellRenderer: this.actionsRender,
				minWidth: 50,
				resizable: true,
				pinned: "right",
			});
		}
		registerModule(this.$store, this.vuexStore, this.filterModel);
		this.filterModel = this.$store.getters[`${this.vuexStore}/filters`];
	}
	private gridVMList: Vue[] = [];
	actionsRender(params: ICellRendererParamsTyped<PortalUser>): HTMLElement {
		const vm = new Vue({
			el: document.createElement("div"),
			render: (createElement) => {
				return createElement(GridActionsRenderer, {
					props: {
						rowIndex: params.rowIndex,
						row: params.data,
						isEdit: this.canEditUser(params.data),
						// NOTE (York): The logic before I changed is a fallback strategy.
						// When current user cannot edit a user, fallback to view if there
						// is VIEW_USERS permission.
						isView:
							!this.canEditUser(params.data) &&
							this.hasPermission(this.VIEW_USERS),
					},
					on: {
						clickEdit: this.onClickEdit,
						clickView: this.onClickView,
					},
				});
			},
		});

		this.gridVMList.push(vm);
		return vm.$el as HTMLElement;
	}

	onClickEdit({ row }: { row: PortalUser }) {
		this.$router.push({
			name: "Edit User",
			params: {
				mode: "edit",
				userId: row.id.toString(),
			},
		});
	}

	onClickView({ row }: { row: PortalUser }) {
		this.$router.push({
			name: "View User",
			params: {
				mode: "view",
				userId: row.id.toString(),
			},
		});
	}

	private onGridReady() {
		this.gridReady = true;
	}

	private reloadGrid(): void {
		this.gridReady = false;
		if (!this.$refs.gridEl) {
			return;
		}
		this.$refs.gridEl.reload();
		this.gridReady = true;
	}

	get selectedReportingCenter() {
		return this.$store.state.persistent.selectedEntities;
	}
	//This is just an example of how we go about using employerselector.
	@Watch("selectedReportingCenter")
	onSelectedReportingCenterChanged() {
		this.retrievePortalUsers();
	}

	private retrievePortalUsers(): void {
		this.reloadGrid();
	}
	getRows(params: IServerSideGetRowsParamsTyped<PortalUser>): void {
		this.filterModel.role.value = this.selectedRole
			.map((role) => role.value)
			.toString();
		params.request.filterModel = Object.keys(this.filterModel).map(
			(key) => {
				return this.filterModel[key];
			}
		);
		if (
			isOnlyOneChildlessEmployerSelected(
				this.selectedEntities,
				this.employerHierarchy
			)
		) {
			this.errorMessage = NO_RC_ERROR_MESSAGE;
			params.successCallback([]);
			return;
		}
		const value = this.$store.state.persistent.selectedEntities;
		axios
			.get<PagedResult<PortalUser>>(portalUserListURL(), {
				params: {
					grid: params.request,
					entities: value,
				},
				cancelToken: params.cancelToken,
			})
			.then((response) => {
				const pagedResultData = response.data;
				params.successCallback(pagedResultData);
			})
			.catch((error) => {
				if (axiosStatic.isCancel(error)) {
					return;
				}
				toastErrorMessage(parseErrorMessage(error));
			});
	}

	/**
	 * Not allowed to edit users:
	 * - sponsor or domain users at MVP (CHSN-1067)
	 * - current login user (CHSN-961)
	 * - when login user has no EDIT_USERS permission
	 */
	canEditUser(user: PortalUser) {
		return this.hasEditPermissionOnUser(user) && !user.ownRecord;
	}

	hasEditPermissionOnUser(user: PortalUser) {
		if (isDomainUser(user)) {
			return hasPermission(this.EDIT_DOMAIN_USERS);
		} else if (isSponsorUser(user)) {
			return hasPermission(this.EDIT_SPONSOR_USERS);
		} else {
			return hasPermission(this.EDIT_USERS);
		}
	}

	hasPermission(permission: string) {
		return hasPermission(permission);
	}

	private clearErrorMessage() {
		this.errorMessage = null;
	}

	private async created() {
		await axios
			.get<{ roles: RoleOption[] }>(portalRolesListURL())
			.then((response) => {
				this.roleOptions = response.data.roles;
			})
			.catch((error) => {
				toastErrorMessage(parseErrorMessage(error));
			});
	}

	onClickAdd() {
		this.$router.push({
			name: "Add User",
			params: { mode: "add" },
		});
	}

	private onResetFilter() {
		this.filterModel.name.value = "";
		this.filterModel.email.value = "";
		this.selectedRole = [];
		this.filterModel.status.value = AccountStatus.ACTIVE;
		this.onApplyFilter();
	}

	private onApplyFilter() {
		commitToModule(this.$store, this.vuexStore, this.filterModel);
		this.$refs.gridEl.reload();
	}
	private getLabel(option: RoleOption) {
		return option.text;
	}

	exportCsv() {
		const entities = [...this.$store.state.persistent.selectedEntities];
		downloadFile(
			"/api/users/export",
			`Users_${moment().format("YYYY-MM-DD")}.csv`,
			{ entities }
		);
	}
}
