




































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import Grid from "@/grid/Grid.vue";
import EmployerSelector from "@/components/EmployerSelector.vue";
import {
	ColDef,
	ColGroupDef,
	IServerSideDatasourceTyped,
	IServerSideGetRowsParamsTyped,
	ICellRendererParamsTyped,
} from "ag-grid-community";
import LinkFundFormat from "@/components/LinkFundFormat.vue";
import axios, { axiosStatic } from "@/utils/ApiUtils";
import { fundFormatLinkDelete, fundFormatList } from "@/constants/apiconstants";
import { PagedResult } from "@/grid/gridTypes";
import { TSelectLevel } from "@/components/employerSelectorTypes";
import { createNamespacedHelpers } from "vuex";
import Button from "@/form/Button.vue";
import { toastErrorMessage, toastSuccessMessage } from "@/plugins/toasts";
import { parseErrorMessage } from "@/utils/ErrorUtils";
import {
	DeleteFundRowParam,
	FundFormat,
	FundFormatIndex,
} from "@/models/FundFormatRow";
import FundFormatActionsRenderer from "@/grid/FundFormatActionsRenderer.vue";

const { mapState } = createNamespacedHelpers("persistent");

@Component({
	components: {
		Layout,
		Grid,
		EmployerSelector,
		Button,
		LinkFundFormat: LinkFundFormat,
	},
	computed: mapState([
		"selectedEntities",
		"employerHierarchy",
		"iressOperations",
		"sponsorPermissions",
	]),
})
export default class EmployerFundFormatPage
	extends Vue
	implements IServerSideDatasourceTyped
{
	private readonly gridVMList: Vue[] = [];
	private readonly pageContext: TSelectLevel = "ALL";
	private readonly errorMessage: string | null = null;

	private gridReady = false;
	private selectedEntity: string | null = null;
	private showAddDialog = false;
	public $refs!: {
		gridEl: Grid;
	};

	private readonly columnDefs: (ColGroupDef | ColDef)[] = [
		{
			headerName: "Fund Format",
			field: "fundFormatName",
			resizable: true,
		},
		{
			headerName: "Entry level",
			field: "entryLevel",
			width: 30,
			resizable: true,
		},
		{
			headerName: "Action",
			cellRenderer: this.actionsRender,
			field: "deletable",
			width: 30,
			resizable: true,
			pinned: "right",
		},
	];

	private showLinkFundDialog(): void {
		if (!this.selectedEntity) {
			toastErrorMessage("No employer or parent is selected.");
		} else {
			this.showAddDialog = true;
		}
	}

	private onClose(): void {
		this.showAddDialog = false;
	}

	private onDone(): void {
		this.showAddDialog = false;
		this.reloadGrid();
	}

	actionsRender(
		params: ICellRendererParamsTyped<FundFormatIndex>
	): HTMLElement {
		const vm = new Vue({
			el: document.createElement("div"),
			render: (createElement) => {
				return createElement(FundFormatActionsRenderer, {
					props: {
						rowIndex: params.rowIndex,
						fundFormatId: params.data.id,
						deletable: params.data.deletable,
						rowFundFormatName: params.data.fundFormatName,
					},
					on: {
						clickDelete: this.onDeleteRow,
					},
				});
			},
		});
		this.gridVMList.push(vm);
		return vm.$el as HTMLElement;
	}

	private onDeleteRow(params: DeleteFundRowParam): void {
		axios
			.get<PagedResult<FundFormat>>(fundFormatLinkDelete(), {
				params: {
					fundFormatId: params.fundFormatId,
					entities: this.$store.state.persistent.selectedEntities,
				},
			})
			.then(() => {
				this.reloadGrid();
				toastSuccessMessage("A fund format has been removed.");
			})
			.catch((rejected) => {
				toastErrorMessage(parseErrorMessage(rejected));
			});
	}

	@Watch("selectedEntities", { immediate: true })
	private getSelectedEntities(): void {
		if (this.$store.state.persistent.selectedEntities.length > 0) {
			this.selectedEntity =
				this.$store.state.persistent.selectedEntities[0];
		}
		this.retrieveFundFormats();
	}

	private onGridReady(): void {
		this.gridReady = true;
	}

	private retrieveFundFormats(): void {
		this.reloadGrid();
	}

	private reloadGrid(): void {
		this.gridReady = false;
		if (!this.$refs.gridEl) {
			return;
		}
		this.$refs.gridEl.reload();
		this.gridReady = true;
	}

	getRows(params: IServerSideGetRowsParamsTyped<FundFormatIndex>): void {
		axios
			.get<PagedResult<FundFormatIndex>>(fundFormatList(), {
				params: {
					grid: params.request,
					entities: this.$store.state.persistent.selectedEntities,
				},
			})
			.then((response) => {
				params.successCallback(response.data);
			})
			.catch((rejected) => {
				if (axiosStatic.isCancel(rejected)) {
					return;
				}
				params.failCallback();
				toastErrorMessage(parseErrorMessage(rejected));
			});
	}
}
