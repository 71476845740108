

































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import Grid from "@/grid/Grid.vue";
import EmployerSelector from "@/components/EmployerSelector.vue";
import { TSelectLevel } from "@/components/employerSelectorTypes";
import Button from "@/form/Button.vue";
import { hasPermission } from "@/utils/PermissionUtils";
import { toastErrorMessage } from "@/plugins/toasts";
import {
	isOnlyOneChildlessEmployerSelected,
	isOnlyOneReportingCentreSelected,
} from "@/utils/EmployerSelectorUtils";
import {
	ColDef,
	ColGroupDef,
	ICellRendererParamsTyped,
	IServerSideDatasourceTyped,
	IServerSideGetRowsParamsTyped,
} from "ag-grid-community";
import { Fund } from "@/pages/fundTypes";
import axios, { axiosStatic } from "@/utils/ApiUtils";
import { searchFundsForRCURL } from "@/constants/apiconstants";
import { parseErrorMessage } from "@/utils/ErrorUtils";
import Accordion from "@/components/Accordion.vue";
import GridActionsRenderer from "@/grid/GridActionsRenderer.vue";
import { FilterModel, PagedResult } from "@/grid/gridTypes";
import { createNamespacedHelpers } from "vuex";
import { EmployerHierarchy } from "@/store/modules/persistent/persistentTypes";
import { NO_RC_ERROR_MESSAGE } from "@/constants/constants";
import GridFilter from "@/components/GridFilter.vue";
import TextField from "@/form/TextField.vue";
import SelectField from "@/form/SelectField.vue";
import { downloadFile } from "@/utils/DownloadUtils";
import moment from "moment";
import { fundType } from "@/constants/pageConstants";
import { commitToModule, registerModule } from "@/store/modules/filters";

const { mapState } = createNamespacedHelpers("persistent");

@Component({
	components: {
		Layout,
		Button,
		EmployerSelector,
		Grid,
		Accordion,
		GridFilter,
		TextField,
		SelectField,
	},
	computed: mapState(["selectedEntities", "employerHierarchy"]),
})
export default class FundListPage
	extends Vue
	implements IServerSideDatasourceTyped
{
	/**
	 * Type the mapped persistent.selectedEntities getter.
	 * It is a computed property. Do not mutate it.
	 */
	selectedEntities!: string[];

	/**
	 * Type the mapped persistent.employerHierarchy getter.
	 * It is a computed property. Do not mutate it.
	 */
	employerHierarchy!: EmployerHierarchy[];

	private vuexStore = "fundsPage";
	private pageContext: TSelectLevel = "ALL";
	private multiSelect = true;
	private errorMessage: string | null = null;
	private gridReady = false;
	private gridVMList: Vue[] = [];

	public $refs!: {
		gridEl: Grid;
	};

	private readonly columnDefs: (ColGroupDef | ColDef)[] = [
		{
			headerName: "Employer",
			field: "employerName",
			minWidth: 200,
			resizable: true,
		},
		{
			headerName: "Reporting centre",
			field: "reportingCentreName",
			minWidth: 200,
			resizable: true,
		},
		{
			headerName: "Payee Code",
			field: "payeeCode",
			width: 150,
			resizable: true,
		},
		{
			headerName: "Fund name",
			field: "fundName",
			minWidth: 140,
			resizable: true,
		},
		{
			headerName: "USI",
			field: "usi",
			minWidth: 100,
			resizable: true,
		},
		{
			headerName: "ABN",
			field: "abn",
			minWidth: 100,
			resizable: true,
		},
		{
			headerName: "Status",
			field: "status",
			width: 120,
			resizable: true,
		},
		{
			headerName: "Type",
			field: "type",
			width: 120,
			resizable: true,
		},
		{
			headerName: "Fund employer number",
			field: "fen",
			minWidth: 20,
			resizable: true,
		},
		{
			headerName: "Actions",
			cellRenderer: this.actionsRender,
			minWidth: 50,
			resizable: true,
			pinned: "right",
		},
	];

	private filterModel: FilterModel = {
		type: {
			value: "",
			column: "type",
		},
		fundName: {
			value: "",
			column: "fundName",
		},
		usi: {
			value: "",
			column: "usi",
		},
		abn: {
			value: "",
			column: "abn",
		},
	};

	readonly fundTypeOptions = fundType;

	private rowSelection = "single";
	private viewFundPermission: boolean | undefined;

	hasPermission(permission: string) {
		return hasPermission(permission);
	}

	private reloadGrid(): void {
		this.gridReady = false;
		if (!this.$refs.gridEl) {
			return;
		}
		this.$refs.gridEl.reload();
		this.gridReady = true;
	}

	get selectedReportingCenter() {
		return this.$store.state.persistent.selectedEntities;
	}

	@Watch("selectedReportingCenter")
	onSelectedReportingCenterChanged() {
		this.retrieveFund();
	}

	private retrieveFund(): void {
		this.reloadGrid();
	}

	onClickEdit({
		row: { payeeCode, type: fundType, reportingCentreId },
	}: {
		row: Fund;
	}) {
		this.$router.push({
			name: "Edit Fund",
			params: {
				payeeCode,
				fundType,
				reportingCentreId,
			},
		});
	}

	onClickView({
		row: { payeeCode, type: fundType, reportingCentreId },
	}: {
		row: Fund;
	}) {
		this.$router.push({
			name: "View Fund",
			params: {
				payeeCode,
				fundType,
				reportingCentreId,
			},
		});
	}

	actionsRender(params: ICellRendererParamsTyped<Fund>): HTMLElement {
		let isEdit =
			params.data.status === "Active" &&
			this.hasPermission("MAINTAIN_FUNDS");
		const isEditSensitiveData =
			params.data.type === "SMSF" &&
			this.hasPermission("EDIT_FUND_SENSITIVE");
		//For smsf check if EDIT_FUND_SENSITIVE permission is present
		if (params.data.type === "SMSF") {
			isEdit = isEdit && isEditSensitiveData;
		}

		const vm = new Vue({
			el: document.createElement("div"),
			render: (createElement) => {
				return createElement(GridActionsRenderer, {
					props: {
						rowIndex: params.rowIndex,
						row: params.data,
						isEdit: isEdit,
						isView: !isEdit && this.viewFundPermission,
					},
					on: {
						clickEdit: this.onClickEdit,
						clickView: this.onClickView,
					},
				});
			},
		});
		this.gridVMList.push(vm);
		return vm.$el as HTMLElement;
	}

	private onGridReady() {
		this.gridReady = true;
	}
	beforeMount() {
		this.viewFundPermission = this.hasPermission("VIEW_FUNDS");
	}
	created() {
		registerModule(this.$store, this.vuexStore, this.filterModel);
		this.filterModel = this.$store.getters[`${this.vuexStore}/filters`];
	}
	getRows(params: IServerSideGetRowsParamsTyped<Fund>): void {
		if (
			isOnlyOneChildlessEmployerSelected(
				this.selectedEntities,
				this.employerHierarchy
			)
		) {
			this.errorMessage = NO_RC_ERROR_MESSAGE;
			params.successCallback([]);
			return;
		}

		params.request.filterModel = Object.keys(this.filterModel).map(
			(key) => {
				return this.filterModel[key];
			}
		);

		const fd = new FormData();
		const value =
			this.$store.state.persistent.selectedEntities.length == 0
				? []
				: this.$store.state.persistent.selectedEntities;
		fd.append("entities", value);
		fd.append("gridOptions", JSON.stringify(params.request));

		axios
			.get<{ data: PagedResult<Fund> }>(searchFundsForRCURL(), {
				params: {
					entities: [
						...this.$store.state.persistent.selectedEntities,
					],
					gridOptions: params.request,
				},
				cancelToken: params.cancelToken,
			})
			.then((response) => {
				const pagedResultData = response.data.data;
				params.successCallback(pagedResultData);
			})
			.catch((error) => {
				if (axiosStatic.isCancel(error)) {
					return;
				}
				toastErrorMessage(parseErrorMessage(error));
			});
	}

	addFund() {
		if (this.selectedEntities.length <= 0) {
			this.errorMessage = "Please select a reporting centre";
			return;
		}

		if (
			!isOnlyOneReportingCentreSelected(
				this.selectedEntities,
				this.employerHierarchy
			)
		) {
			this.errorMessage = "Please select only one reporting centre";
			return;
		}

		if (!this.showAddFund) {
			toastErrorMessage(
				"You do not have permissions to add fund for the selected Reporting Centre"
			);
			return;
		}

		this.$router.push({
			name: "Add Fund",
		});
	}

	clearErrorMessage() {
		this.errorMessage = null;
	}

	constructor() {
		super();
	}

	get showAddFund() {
		return hasPermission("ADD_FUND");
	}

	private onApplyFilter() {
		commitToModule(this.$store, this.vuexStore, this.filterModel);
		this.$refs.gridEl.reload();
	}

	onResetFilter() {
		this.filterModel.usi.value = "";
		this.filterModel.abn.value = "";
		this.filterModel.fundName.value = "";
		this.filterModel.type.value = "";

		this.onApplyFilter();
	}

	exportCsv() {
		const entities = [...this.$store.state.persistent.selectedEntities];
		downloadFile(
			"/api/fund/export",
			`Funds_${moment().format("YYYY-MM-DD")}.csv`,
			{ entities }
		);
	}
}
