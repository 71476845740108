


































































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import Button from "@/form/Button.vue";
import axios, { axiosStatic } from "@/utils/ApiUtils";
import { newsURL } from "@/constants/apiconstants";
import ModalOrPopup from "@/components/ModalOrPopup.vue";
import SelectField from "@/form/SelectField.vue";
import FieldGroup from "@/form/FieldGroup.vue";
import TextField from "@/form/TextField.vue";
import TextareaField from "@/form/TextareaField.vue";
import Form from "@/form/Form.vue";
import DatepickerField from "@/form/DatepickerField.vue";
import ButtonBehaviour from "@/form/ButtonBehaviour.vue";
import Grid from "@/grid/Grid.vue";
import {
	ColDef,
	ColGroupDef,
	ICellRendererParamsTyped,
	IServerSideDatasourceTyped,
} from "ag-grid-community";
import GridActionsRenderer from "@/grid/GridActionsRenderer.vue";
import { parseErrorMessage } from "@/utils/ErrorUtils";
import { ValidationObserver } from "vee-validate";
import { columnDateFormatter } from "@/utils/CommonUtils";
import { toastErrorMessage, toastSuccessMessage } from "@/plugins/toasts";
import PageHeader from "@/components/PageHeader.vue";
import { Logger } from "@/utils/logger";
import MultiSelect from "@/form/MultiSelect.vue";
import { SelectOption } from "@/form/FieldOptions";
import { PagedResult } from "@/grid/gridTypes";
import { hasPermission } from "@/utils/PermissionUtils";

class News {
	id = 0;
	slotType = "DASHBOARD";
	sponsorId: number | null = null;
	sponsorName = "";
	title = "";
	body = "";
	readMoreUrl = "";
	showFrom = "";
	showUntil = "";
	defaultInd = "N";
}

interface Sponsor {
	id: number;
	sponsorName: string;
}

@Component({
	components: {
		PageHeader,
		DatepickerField,
		TextField,
		TextareaField,
		SelectField,
		Button,
		Layout,
		ModalOrPopup,
		FieldGroup,
		Form,
		ButtonBehaviour,
		Grid,
		GridActionsRenderer,
		ValidationObserver,
		MultiSelect,
	},
})
export default class NewsPage extends Vue {
	private defaultNews: Array<News> = [];
	private news: News = new News();
	private slotOptions: SelectOption[] = [];
	private sponsorOptions: Sponsor[] = [];
	private selectedSponsors: Sponsor[] = [];

	private showAddForm = false;
	private showDeleteForm = false;
	private saveConfirmation = false;
	private deleteConfirmation = false;

	private gridVMCustomNewsList: Vue[] = [];
	private gridVMDefaultNewsList: Vue[] = [];

	public $refs!: {
		customNewsGrid: Grid;
	};

	actionsRender(params: ICellRendererParamsTyped<News>): HTMLElement {
		const vm = new Vue({
			el: document.createElement("div"),
			render: (createElement) => {
				return createElement(GridActionsRenderer, {
					props: {
						rowIndex: params.rowIndex,
						row: params.data,
						isEdit: true,
						isDelete: hasPermission("DELETE_NEWS"),
					},
					on: {
						clickEdit: this.onUpdate,
						clickDelete: this.onDeleteItem,
					},
				});
			},
		});
		this.gridVMCustomNewsList.push(vm);
		return vm.$el as HTMLElement;
	}

	private readonly columnDefs: (ColGroupDef | ColDef)[] = [
		{
			headerName: "Sponsor",
			field: "sponsorName",
			resizable: true,
			width: 150,
		},
		{
			headerName: "Slot",
			field: "slotType",
			resizable: true,
			width: 150,
		},
		{
			headerName: "Title",
			field: "title",
			resizable: true,
			width: 400,
		},
		{
			headerName: "Show from",
			field: "showFrom",
			resizable: true,
			width: 150,
			valueFormatter: columnDateFormatter,
		},
		{
			headerName: "Show until",
			field: "showUntil",
			resizable: true,
			width: 150,
			valueFormatter: columnDateFormatter,
		},
		{
			headerName: "Edit",
			field: "__Actions",
			cellRenderer: this.actionsRender,
			minWidth: 100,
			maxWidth: 100,
		},
	];

	actionsDefaultRender(params: ICellRendererParamsTyped<News>): HTMLElement {
		const vm = new Vue({
			el: document.createElement("div"),
			render: (createElement) => {
				return createElement(GridActionsRenderer, {
					props: {
						rowIndex: params.rowIndex,
						row: params.data,
						isEdit: true,
					},
					on: {
						clickEdit: this.onUpdateDefault,
					},
				});
			},
		});
		this.gridVMDefaultNewsList.push(vm);
		return vm.$el as HTMLElement;
	}

	private readonly columnDefsDefault: (ColGroupDef | ColDef)[] = [
		{
			headerName: "Sponsor",
			field: "sponsorName",
			resizable: true,
			width: 150,
		},
		{
			headerName: "Slot",
			field: "slotType",
			resizable: true,
			width: 150,
		},
		{
			headerName: "Title",
			field: "title",
			resizable: true,
			width: 400,
		},
		{
			headerName: "Edit",
			field: "__Actions",
			cellRenderer: this.actionsDefaultRender,
			minWidth: 100,
			maxWidth: 100,
		},
	];

	mounted() {
		this.retrieveSlots();
		this.retrieveSponsors();
		this.retrieveDefaultNews();
	}

	destroyed() {
		for (const vm of this.gridVMCustomNewsList) {
			vm.$destroy();
		}
		this.gridVMCustomNewsList = [];
		for (const vm of this.gridVMDefaultNewsList) {
			vm.$destroy();
		}
		this.gridVMDefaultNewsList = [];
	}

	private onAdd() {
		this.onDismiss();
		this.news = new News();
		this.showAddForm = true;
	}

	private onCancel() {
		this.showAddForm = false;
		this.showDeleteForm = false;
		this.selectedSponsors = [];
	}

	private onSave() {
		if (this.news.id === 0) {
			const newsPayload: News[] = this.selectedSponsors.map(
				(sponsor: Sponsor) => {
					return {
						...this.news,
						sponsorId: sponsor.id,
						sponsorName: sponsor.sponsorName,
					};
				}
			);
			axios
				.post(newsURL(), newsPayload)
				.then((response) => {
					this.selectedSponsors = [];
					this.showAddForm = false;
					this.saveConfirmation = true;
					toastSuccessMessage(
						this.news.title + " has been saved successfully."
					);
					this.refreshCustomNews();
				})
				.catch((error) => {
					toastErrorMessage(parseErrorMessage(error));
				});
		} else {
			axios
				.put(newsURL(), this.news)
				.then((response) => {
					this.showAddForm = false;
					this.saveConfirmation = true;
					toastSuccessMessage(
						this.news.title + " has been saved successfully."
					);
					if (this.news.defaultInd == "Y") {
						this.retrieveDefaultNews();
					} else {
						this.refreshCustomNews();
					}
				})
				.catch((error) => {
					toastErrorMessage(parseErrorMessage(error));
				});
		}
	}

	private onDeleteItem({ rowIndex, row }: { rowIndex: number; row: News }) {
		this.onDismiss();
		this.news = row;
		this.showDeleteForm = true;
	}

	private onDeleteConfirmation() {
		axios
			.delete(newsURL() + "/" + this.news.id)
			.then((response) => {
				this.showAddForm = false;
				this.refreshCustomNews();
				this.deleteConfirmation = true;
				toastSuccessMessage(
					this.news.title + " has been deleted successfully."
				);
			})
			.catch((error) => {
				toastErrorMessage(parseErrorMessage(error));
			});

		this.showDeleteForm = false;
	}

	private onUpdate({ rowIndex, row }: { rowIndex: number; row: News }) {
		this.onDismiss();
		this.news = row;
		this.showAddForm = true;
	}

	private onUpdateDefault({
		rowIndex,
		row,
	}: {
		rowIndex: number;
		row: News;
	}) {
		this.onDismiss();
		this.news = row;
		this.showAddForm = true;
	}

	private onDismiss() {
		this.saveConfirmation = false;
		this.deleteConfirmation = false;
	}

	get customNewsDatasource(): IServerSideDatasourceTyped<News> {
		return {
			getRows(params): void {
				axios
					.get<PagedResult<News>>(newsURL(), {
						cancelToken: params.cancelToken,
						params: {
							grid: params.request,
							defaultInd: "N",
						},
					})
					.then((response) => {
						params.successCallback(response.data);
					})
					.catch((error) => {
						Logger.log(error);
						if (axiosStatic.isCancel(error)) {
							return;
						}
					});
			},
		};
	}

	private retrieveSlots() {
		axios
			.get<string[]>(`${newsURL()}/slot`)
			.then((response) => {
				this.slotOptions = [];
				const options = response.data;
				options.forEach((o: string) => {
					this.slotOptions.push({
						value: o,
						label: o,
					});
				});
			})
			.catch((error) => {
				Logger.log(error);
				//Default values - should not happen
				this.slotOptions.push({
					value: "DASHBOARD",
					label: "DASHBOARD",
				});
			});
	}

	private retrieveSponsors() {
		axios
			.get<Sponsor[]>(`${newsURL()}/sponsors`)
			.then((response) => {
				this.sponsorOptions = response.data;
			})
			.catch((error) => {
				Logger.log(error);
			});
	}

	private retrieveDefaultNews() {
		axios
			.get<PagedResult<News>>(newsURL(), {
				params: {
					defaultInd: "Y",
				},
			})
			.then((response) => {
				this.defaultNews = response.data.elements;
			})
			.catch((error) => {
				Logger.log(error);
			});
	}

	private getSponsorLabel(option: Sponsor) {
		return option.sponsorName;
	}

	private refreshCustomNews(): void {
		if (!this.$refs.customNewsGrid) {
			return;
		}
		this.$refs.customNewsGrid.refresh();
	}
}
