



























































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import Button from "@/form/Button.vue";
import CompanyDetails from "@/components/CompanyDetails.vue";
import PageHeader from "@/components/PageHeader.vue";
import ModalWithSaveAndCancel from "@/components/ModalWithSaveAndCancel.vue";
import CompanyConfigDetails from "@/components/CompanyConfigDetails.vue";
import UserDetails from "@/components/UserDetails.vue";
import {
	Field,
	HierarchiesToAccess,
	RoleOption,
} from "@/pages/maintenanceTypes";
import axios from "@/utils/ApiUtils";
import {
	portalRolesListURL,
	portalSponsorsListURL,
	portalUserURL,
} from "@/constants/apiconstants";
import SubHeading from "@/components/SubHeading.vue";
import CheckBoxGroup from "@/form/CheckBoxGroup.vue";
import { createNamespacedHelpers } from "vuex";
import { EmployerHierarchy } from "@/store/modules/persistent/persistentTypes";
import { difference, filter, findIndex, forEach, uniq } from "lodash";
import {
	ColDef,
	ColGroupDef,
	ICellRendererParamsTyped,
	ValueGetterParamsTyped,
} from "ag-grid-community";
import Grid from "@/grid/Grid.vue";
import CheckBoxRenderer from "@/grid/cellrenderers/CheckBoxRenderer.vue";
import { EMPLOYER, hasPermission, PARENT, RC } from "@/utils/PermissionUtils";
import {
	toastErrorMessage,
	toastSuccessMessage,
	toastWarningMessage,
} from "@/plugins/toasts";
import { parseErrorMessage } from "@/utils/ErrorUtils";
import BrandList from "@/components/BrandList.vue";
import { VForm } from "@/@typings/type-vee-validate";
import { AppRoute, AppRouteNextFunction } from "@/router";
import { PortalUser } from "@/models/PortalUser";
import { CheckBoxGroupOption } from "@/form/FieldOptions";
import {
	isDomainUser,
	isSponsorUser,
} from "@/pages/maintenance/PortalUserUtils";

/**
 * This should correspond to the Java class au.com.iress.clearinghouse.portal.user.UserRestService.UserResponse
 */
interface UserResponse {
	user: PortalUser;
	roles: RoleOption[];
	employerTreeHierarchy: EmployerHierarchy[];
	userRoleIds: number[];
	entities: string[];
	canEditRoles: boolean;
	userSponsorIds: number[];
	sponsors: CheckBoxGroupOption[];
}
// Edit user roles
const EDIT_USERS = "EDIT_USERS";
const EDIT_DOMAIN_USERS = "EDIT_DOMAIN_USERS";
const EDIT_SPONSOR_USERS = "EDIT_SPONSOR_USERS";

function getPortalUser(userId: number) {
	return axios
		.get<UserResponse>(portalUserURL(userId))
		.then((response) => response.data)
		.catch((error) => toastErrorMessage(parseErrorMessage(error)));
}

function getPortalUserRoles() {
	return axios
		.get<{ roles: RoleOption[] }>(portalRolesListURL())
		.then((response) => response.data)
		.catch((error) => toastErrorMessage(parseErrorMessage(error)));
}

function getSponsors() {
	return axios
		.get<CheckBoxGroupOption[]>(portalSponsorsListURL())
		.then((response) => response.data)
		.catch((error) => toastErrorMessage(parseErrorMessage(error)));
}

function hasEditPermissionOnUser(user: PortalUser) {
	if (isDomainUser(user)) {
		return hasPermission(EDIT_DOMAIN_USERS);
	} else if (isSponsorUser(user)) {
		return hasPermission(EDIT_SPONSOR_USERS);
	} else {
		return hasPermission(EDIT_USERS);
	}
}

const { mapState } = createNamespacedHelpers("persistent");
const noParentEmployerPrefix = "P_O_";
@Component({
	components: {
		SubHeading,
		UserDetails,
		CompanyConfigDetails,
		PageHeader,
		CompanyDetails,
		ModalWithSaveAndCancel,
		Layout,
		Button,
		CheckBoxGroup,
		Grid,
		BrandList,
	},
	computed: mapState([
		"selectedEntities",
		"employerHierarchy",
		"brandId",
		"iressOperations",
	]),
})
export default class AddOrEditPortalUsers extends Vue {
	/**
	 * Type the mapped persistent.employerHierarchy getter.
	 * It is a computed property. Do not mutate it.
	 */
	employerHierarchy!: EmployerHierarchy[];

	/**
	 * Type the mapped persistent.selectedEntities getter.
	 * It is a computed property. Do not mutate it.
	 */
	selectedEntities!: string[];

	/**
	 * Type the mapped persistent.selectedEntities getter.
	 * It is a computed property. Do not mutate it.
	 * hide brandList component if its
	 */
	brandId!: number | null;

	/**
	 * Type the mapped persistent.selectedEntities getter.
	 * It is a computed property. Do not mutate it.
	 * Enable Iress operations user to select entities
	 */
	iressOperations!: number | null;

	public $refs!: {
		gridEl: Grid;
		userDetailsForm: VForm;
	};
	private showCancelModal = false;
	private isFormDirty = false;

	@Prop(String) pageMode!: "add" | "edit" | "view";

	/**
	 * Route params prop, only used when pageMode is edit/view.
	 */
	@Prop(Number) userId: number | undefined;

	/**
	 * - Empty object in add user page.
	 * - Initialised in beforeRouterEnter in edit/view user page.
	 */
	private userDetail: PortalUser = {
		id: 0,
		title: null,
		name: "",
		position: null,
		email: "",
		mobilePhone: null,
		lastLogin: null,
		suspend: false,
		ownRecord: false,
		admin: false,
		brandId: null,
		sponsorId: null,
		domainId: null,
	};

	private canEditRoles = false;
	// Role selected can either show or hide Sponsors
	private showSponsors = false;

	private roleOptions: RoleOption[] = [];
	private sponsorOptions: CheckBoxGroupOption[] = [];
	// keeps track of selected entities
	private applyRolesToEntities: string[] = [];
	private hierarchiesToAccess: HierarchiesToAccess[] = [];

	private parentChildRelationship: { [key: string]: string[] } = {};
	private childParentRelationship: { [key: string]: string } = {};

	private loading = false;
	private userRoleIds: number[] = [];
	private userSponsorIds: number[] = [];

	private orphanParent = true;

	private columnDefs: (ColGroupDef | ColDef)[] = [
		{
			field: "parentLabel",
			hide: true,
			rowGroup: true,
		},
		{
			field: "employerLabel",
			hide: true,
			rowGroup: true,
		},
		{
			headerName: "Reporting centre",
			field: "rcLabel",
			minWidth: 300,
			resizable: true,
			menuTabs: ["filterMenuTab"],
			filter: true,
			filterParams: {
				buttons: ["reset"],
				applyMiniFilterWhileTyping: true,
			},
		},
		{
			headerName: "Apply selection roles",
			minWidth: 20,
			resizable: true,
			cellRenderer: this.checkBoxRender,
		},
	];

	private autoGroupColumnDef = {
		minWidth: 200,
		menuTabs: ["filterMenuTab"],
		filter: true,
		filterParams: {
			buttons: ["reset"],
			applyMiniFilterWhileTyping: true,
		},
		filterValueGetter: (
			params: ValueGetterParamsTyped<HierarchiesToAccess>
		) =>
			params.data[
				params.colDef.showRowGroup as keyof HierarchiesToAccess
			],
	};

	onGridReady() {
		// NOTE: If "apply" button is not visible, filter popup would not close even `closeOnApply: true` is set.
		// If `closeOnApply: true` is set, it is not compatablie with `applyMiniFilterWhileTyping: true`. Any
		// typing in mini filter would close the filter popup. Here is a hack that changes the default behaviour
		// of Reset button. It closes filter popup in the end.
		[
			"ag-Grid-AutoColumn-parentLabel",
			"ag-Grid-AutoColumn-employerLabel",
			"rcLabel",
		].forEach((field) => {
			const filterInstance: any =
				this.$refs.gridEl.api?.getFilterInstance(field);
			filterInstance.onBtReset = function () {
				this.onBtClear();
				this.onBtApply();
				this.close();
			};
		});
	}

	private gridVMList: Vue[] = [];
	private disableAllCheckboxes = false;

	checkBoxRender(
		params: ICellRendererParamsTyped<HierarchiesToAccess>
	): HTMLElement {
		const vm = new Vue({
			el: document.createElement("div"),
			render: (createElement) => {
				return createElement(CheckBoxRenderer, {
					props: {
						rowIndex: params.rowIndex,
						groupBy: this.getGroupBy(params),
						row: this.getData(params),
						// Note (York): To who are confused about checkbox in the grid cell.
						// `setCheckBoxValue` includes `applyRolesToEntities` in its closure. When
						// `applyRolesToEntities` is changed, i.e, onClickCheckBox -> addOrRemoveEntity,
						// then all checkBoxRender(s) re-render.
						// I don't know if it is good design.
						value: this.setCheckBoxValue(params),
						disable:
							!this.canEditRoles || this.disableAllCheckboxes,
					},
					on: {
						input: this.onClickCheckBox,
					},
				});
			},
		});
		this.gridVMList.push(vm);
		return vm.$el as HTMLElement;
	}

	getGroupBy(params: ICellRendererParamsTyped<HierarchiesToAccess>) {
		if (params.node.level === 0 && !this.orphanParent) {
			return PARENT;
		} else if (
			(params.node.level === 1 && !this.orphanParent) ||
			(params.node.level === 0 && this.orphanParent)
		) {
			return EMPLOYER;
		} else {
			return RC;
		}
	}

	// gets node data for grouped rows which are computed
	getData(params: ICellRendererParamsTyped<HierarchiesToAccess>) {
		if (params.data) {
			return params.data;
		}
		// employer
		else if (params.node?.childrenAfterFilter[0]?.data) {
			return {
				parentId: params.node.childrenAfterFilter[0]?.data.parentId,
				parentLabel:
					params.node.childrenAfterFilter[0]?.data.parentLabel,
				employerLabel:
					params.node.childrenAfterFilter[0]?.data.employerLabel,
				employerId: params.node.childrenAfterFilter[0]?.data.employerId,
			};
		}
		// parent
		else if (
			params.node?.childrenAfterFilter[0]?.childrenAfterFilter[0].data
		) {
			return {
				parentId:
					params.node.childrenAfterFilter[0].childrenAfterFilter[0]
						.data.parentId,
				parentLabel:
					params.node.childrenAfterFilter[0].childrenAfterFilter[0]
						.data.parentLabel,
			};
		}

		return null;
	}

	setCheckBoxValue(params: ICellRendererParamsTyped<HierarchiesToAccess>) {
		const data: HierarchiesToAccess | null = this.getData(params);
		if (!data) {
			return false;
		}
		// rc
		if (data.rcId) {
			if (
				(data.rcId &&
					this.applyRolesToEntities.indexOf(data.rcId) > -1) ||
				(data.employerId &&
					this.applyRolesToEntities.indexOf(data.employerId) > -1) ||
				this.applyRolesToEntities.indexOf(data.parentId) > -1
			) {
				return true;
			}
		} // employer
		else if (data.employerId) {
			if (
				(data.employerId &&
					this.applyRolesToEntities.indexOf(data.employerId) > -1) ||
				this.applyRolesToEntities.indexOf(data.parentId) > -1
			) {
				return true;
			}
		} // parent
		else if (
			data.parentId &&
			params.node.childrenAfterGroup &&
			params.node.childrenAfterGroup[0].childrenAfterGroup
		) {
			const data: HierarchiesToAccess =
				params.node.childrenAfterGroup[0].childrenAfterGroup[0].data;
			if (
				data.parentId &&
				this.applyRolesToEntities.indexOf(data.parentId) > -1
			) {
				return true;
			}
		}

		return false;
	}

	// typically it has to be of type HierarchiesToAccess
	addOrRemoveEntity(data: any, key: string, checkBoxValue: boolean) {
		if (!checkBoxValue) {
			if (key === "employerId") {
				// check if parent is checked
				this.removeParent(data);
			} else if (key === "rcId") {
				this.removeParentAndEmployer(data);
			} else {
				const parentIdx = findIndex(
					this.applyRolesToEntities,
					(entity) => {
						return entity === data[key];
					}
				);
				this.applyRolesToEntities.splice(parentIdx, 1);
			}
		} else {
			// find entity
			const keyIdx = findIndex(this.applyRolesToEntities, (entity) => {
				return entity === data[key];
			});

			// add if not found
			if (data[key] && keyIdx === -1 && checkBoxValue) {
				this.applyRolesToEntities.push(data[key]);
			}

			// Note: automatically rolling up selected checkboxes should be disabled.
			// There's a distinction between explicitly giving a user access to specific entities (employer or RC) over blanket access (at Parent or Employer level)
			// ie: Parent-employer setup (P_1 > E_1 > R_1)
			//  User with explicit RC access - UserRole setup will be (P_1, E_1, R_1) - Only has access to R_1
			//  User with derived RC access - UserRole setup will be (P_1, E_1, R_0) - Has access to R_1 and reporting centres that are yet to be created

			// if (key === "employerId") {
			// 	// check if all entities or rcs have been selected in a branch
			// 	this.rollUpCheckboxSelections();
			// } else if (key === "rcId") {
			// 	// roll up twice, once for rc => employer rolling and then employer => parent
			// 	for (let i = 0; i < 2; i++) {
			// 		this.rollUpCheckboxSelections();
			// 	}
			// }
		}

		// remove all children if parent is set
		this.applyRolesToEntities = filter(
			this.applyRolesToEntities,
			(entityId) => {
				if (
					this.childParentRelationship[entityId] &&
					this.childParentRelationship[entityId].startsWith(
						EMPLOYER
					) &&
					this.childParentRelationship[
						this.childParentRelationship[entityId]
					] &&
					this.applyRolesToEntities.indexOf(
						this.childParentRelationship[
							this.childParentRelationship[entityId]
						]
					) > -1
				) {
					return false;
				} else if (
					!(
						this.childParentRelationship[entityId] &&
						this.applyRolesToEntities.indexOf(
							this.childParentRelationship[entityId]
						) > -1
					)
				) {
					return true;
				} else return false;
			}
		);
		this.applyRolesToEntities = uniq(this.applyRolesToEntities);
	}

	rollUpCheckboxSelections() {
		this.applyRolesToEntities = uniq(this.applyRolesToEntities);

		forEach(this.parentChildRelationship, (value, key) => {
			const contains = value.every((id: string) =>
				this.applyRolesToEntities.includes(id)
			);
			if (contains) {
				this.applyRolesToEntities = [
					...difference(this.applyRolesToEntities, value),
					key,
				];
			}
		});
	}

	removeParent(data: HierarchiesToAccess) {
		const keyIdx = findIndex(this.applyRolesToEntities, (entity) => {
			return entity === data.parentId;
		});

		if (keyIdx > -1) {
			// remove parent
			this.applyRolesToEntities.splice(keyIdx, 1);

			// add children except current entity
			const childrenToAdd: string[] = filter(
				this.parentChildRelationship[data.parentId],
				(entityId) => {
					return entityId !== data.employerId;
				}
			);

			this.applyRolesToEntities = [
				...this.applyRolesToEntities,
				...childrenToAdd,
			];
		}
		// remove a single element  i.e. employer
		else {
			const employerId = findIndex(
				this.applyRolesToEntities,
				(entity) => {
					return entity === data.employerId;
				}
			);

			this.applyRolesToEntities.splice(employerId, 1);
		}
	}

	removeParentAndEmployer(data: HierarchiesToAccess) {
		const keyIdx = findIndex(this.applyRolesToEntities, (entity) => {
			return entity === data.employerId || entity === data.parentId;
		});
		const parentSelected = this.applyRolesToEntities.includes(
			data.parentId
		);
		const standAloneEmployer = data.parentId.startsWith(
			noParentEmployerPrefix
		);
		if (keyIdx > -1 && data.employerId) {
			// remove parent
			this.applyRolesToEntities.splice(keyIdx, 1);
			// employers to add
			// add children except current entity
			const employersToAdd: string[] = filter(
				this.parentChildRelationship[data.parentId],
				(entityId) => {
					return (
						entityId !== data.employerId &&
						(standAloneEmployer || parentSelected || !data.rcId)
					);
				}
			);

			// add children except current rcId
			const childrenToAdd: string[] = filter(
				this.parentChildRelationship[data.employerId],
				(entityId) => {
					return entityId !== data.rcId;
				}
			);
			this.applyRolesToEntities = [
				...this.applyRolesToEntities,
				...employersToAdd,
				...childrenToAdd,
			];

			// remove RC
			if (this.orphanParent) {
				const rcIdx = findIndex(this.applyRolesToEntities, (entity) => {
					return entity === data.rcId;
				});

				this.applyRolesToEntities.splice(rcIdx, 1);
			}
		}
		// remove a single element  i.e. rc
		else {
			const rcIdx = findIndex(this.applyRolesToEntities, (entity) => {
				return entity === data.rcId;
			});

			this.applyRolesToEntities.splice(rcIdx, 1);
		}
	}

	roleOption(value: number) {
		return this.roleOptions.find((ro) => Number(ro.value) === value);
	}

	updateUserRole(value: number[]) {
		// This handles the roleExclusive logic by comparing the previously selected role with new role selected
		const previouslySelected =
			value.length > 1 ? value[value.length - 2] : null;
		let previouslySelectedRole;
		if (previouslySelected) {
			previouslySelectedRole = this.roleOption(previouslySelected);
		}

		const recentlySelected =
			value.length > 0 ? value[value.length - 1] : null;

		let recentlySelectedRole;
		if (recentlySelected) {
			recentlySelectedRole = this.roleOption(recentlySelected);
		}

		if (recentlySelectedRole && previouslySelectedRole) {
			if (recentlySelectedRole.roleExclusive) {
				this.userRoleIds = [];
				this.userRoleIds.push(Number(recentlySelected));
			} else if (
				(!recentlySelectedRole.roleExclusive &&
					previouslySelectedRole.roleExclusive) ||
				recentlySelectedRole.category !==
					previouslySelectedRole.category
			) {
				this.userRoleIds = [];
				this.userRoleIds.push(Number(recentlySelected));
			}
		}

		this.updateRoles();
		this.isFormDirty = true;
	}

	updateRoles() {
		if (this.userRoleIds.length == 1) {
			this.singleRole();
		} else if (this.userRoleIds.length == 0) {
			this.noRole();
		} else {
			this.multipleRoles();
		}
	}

	created() {
		this.buildHierarchiesToAccess();
		if (this.orphanParent) {
			this.columnDefs.shift();
		}
	}

	userPagePostInit() {
		//default brand id to current user's brand id
		this.userDetail.brandId = this.userDetail.brandId ?? this.brandId;
	}

	viewOrEditUserPagePostInit() {
		this.userPagePostInit();

		if (this.userDetail.ownRecord) {
			toastWarningMessage(
				`Your role is currently set as IRESS OPERATIONS and cannot be modified to an employer role.`
			);
		}
		this.updateRoles();

		// Note (Raghu) filter out existing bad data
		// TODO this filter can go in time when the data in the database is clean
		this.applyRolesToEntities = this.applyRolesToEntities.filter(
			(entity) => {
				return (
					!this.orphanParent ||
					(this.orphanParent && !entity.startsWith(PARENT))
				);
			}
		);
	}

	async beforeRouteEnter(
		to: AppRoute,
		from: AppRoute,
		next: AppRouteNextFunction<AddOrEditPortalUsers>
	) {
		const mode = to.params.mode;
		if (mode === "add") {
			const userRolesResponse = await getPortalUserRoles();
			const sponsorsResponse = await getSponsors();
			if (userRolesResponse === undefined) {
				next(
					Error(`Unable to fetch user roles for current login user.`)
				);
				return;
			}
			next((vm) => {
				vm.roleOptions = userRolesResponse.roles;
				vm.userPagePostInit();
				if (sponsorsResponse) {
					vm.sponsorOptions = sponsorsResponse;
					// If logged-in user only has one sponsor, default new user to this sponsor
					if (sponsorsResponse.length == 1) {
						vm.userSponsorIds.push(
							Number(sponsorsResponse[0].value)
						);
					}
				}
				vm.canEditRoles = true;
			});
			return;
		}

		if (mode === "view" || mode === "edit") {
			const userId = Number(to.params.userId);
			const userResponse = await getPortalUser(userId);
			if (userResponse === undefined) {
				next(new Error(`Unable to fetch user [id=${userId}].`));
				return;
			}
			if (mode === "edit" && userResponse.user.ownRecord) {
				const error = `Unable to edit current login user.`;
				toastErrorMessage(error);
				next(new Error(error));
				return;
			}

			if (
				mode === "edit" &&
				!hasEditPermissionOnUser(userResponse.user)
			) {
				const error = `Unable to edit sponsor or domain user.`;
				toastErrorMessage(error);
				next(new Error(error));
				return;
			}

			next((vm) => {
				vm.userDetail = userResponse.user;
				vm.roleOptions = userResponse.roles;
				vm.userRoleIds = userResponse.userRoleIds;
				vm.applyRolesToEntities = userResponse.entities;
				vm.canEditRoles = userResponse.canEditRoles;
				vm.userSponsorIds = userResponse.userSponsorIds;
				vm.sponsorOptions = userResponse.sponsors;
				vm.viewOrEditUserPagePostInit();
			});
			return;
		}

		throw new Error(`Unknown user page mode [${mode}].`);
	}

	async onClickSave() {
		if (await (this.$refs.userDetailsForm as VForm).validate()) {
			if (this.userRoleIds.length === 0) {
				if (this.applyRolesToEntities.length === 0) {
					toastErrorMessage(
						"Please select at least one role and at least one entity from the hierarchy table."
					);
				} else {
					toastErrorMessage("Please select at least one role.");
				}
				return;
			}
			if (this.applyRolesToEntities.length === 0) {
				toastErrorMessage(
					"Please select at least one entity from the hierarchy table."
				);
				return;
			}

			//Sponsor ID is required for roles with showSponsors=true (Sponsor, Sponsor Operations)
			if (this.userRoleIds.length === 1) {
				const selectedRole = this.roleOption(this.userRoleIds[0]);
				if (
					selectedRole &&
					selectedRole.showSponsors &&
					this.userSponsorIds.length === 0
				) {
					toastErrorMessage("Please select at least one sponsor.");
					return;
				}
			}

			//check if any roles have orphan parent names on them
			const entities: string[] = [];
			forEach(this.applyRolesToEntities, (roleId) => {
				if (roleId.startsWith(noParentEmployerPrefix)) {
					entities.push(roleId.split(noParentEmployerPrefix)[1]);
				} else {
					entities.push(roleId);
				}
			});

			let method: "post" | "POST" | "put" | "PUT" = "POST";
			let url: string | null = null;
			if (this.$route.name === "Add User") {
				method = "POST";
				url = `/api/users/add`;
			} else {
				method = "PUT";
				url = `/api/users/${this.$route.params.userId}`;
			}
			if (url && method) {
				axios
					.request<void>({
						method: method,
						url: url,
						data: {
							user: this.userDetail,
							entities: entities,
							roleIds: this.userRoleIds,
							sponsorIds: this.userSponsorIds,
						},
						headers: {
							"Content-Type": "application/json",
						},
					})
					.then((resp) => {
						if (this.$route.name === "Add User") {
							toastSuccessMessage(
								`Create new user with name ${this.userDetail?.name} was successful`
							);
						} else {
							toastSuccessMessage(
								`Edit user ${this.userDetail?.name} was successful`
							);
						}

						this.isFormDirty = false;
						this.$router.push({ name: "Users and roles" });
					})
					.catch((e) => {
						toastErrorMessage(parseErrorMessage(e));
					});
			}
		} else {
			toastErrorMessage("Fields highlighted above have errors.");
		}
	}
	onCancel() {
		if (this.isFormDirty) {
			this.showCancelModal = true;
		} else {
			this.discardChanges();
		}
	}

	// update it via events to avoid data mutation from user-details component
	updateUserDetail(e: { field: Field; value: string }) {
		if (this.userDetail) {
			Vue.set(this.userDetail, e.field, e.value);
			this.isFormDirty = true;
		}
	}

	buildHierarchiesToAccess() {
		const hierarchiesToAccess: HierarchiesToAccess[] = [];
		forEach(this.employerHierarchy, (parentOrg) => {
			// orphan employer
			if (parentOrg.id.startsWith(EMPLOYER)) {
				const employer = {
					employerId: parentOrg.id,
					employerLabel: parentOrg.label,
					parentId: noParentEmployerPrefix + parentOrg.id,
					parentLabel: "N/A",
				};

				forEach(parentOrg.children, (rc) => {
					if (
						!this.parentChildRelationship[
							noParentEmployerPrefix + parentOrg.id
						]
					) {
						this.parentChildRelationship[
							noParentEmployerPrefix + parentOrg.id
						] = [];
					}
					this.parentChildRelationship[
						noParentEmployerPrefix + parentOrg.id
					].push(rc.id);
					this.childParentRelationship[rc.id] = parentOrg.id;
					const reportingCentre = {
						rcId: rc.id,
						rcLabel: rc.label,
					};
					hierarchiesToAccess.push({
						...employer,
						...reportingCentre,
					});
				});
			} else {
				this.orphanParent = false;
				const parent = {
					parentId: parentOrg.id,
					parentLabel: parentOrg.label,
				};

				if (!parentOrg.children || parentOrg.children.length <= 0) {
					hierarchiesToAccess.push({ ...parent });
				}

				forEach(parentOrg.children, (emplyr) => {
					const employer = {
						employerId: emplyr.id,
						employerLabel: emplyr.label,
					};

					if (!this.parentChildRelationship[parentOrg.id]) {
						this.parentChildRelationship[parentOrg.id] = [];
					}
					this.childParentRelationship[emplyr.id] = parentOrg.id;
					this.parentChildRelationship[parentOrg.id].push(emplyr.id);
					if (!emplyr.children || emplyr.children.length <= 0) {
						hierarchiesToAccess.push({
							...parent,
							...employer,
							rcId: undefined,
							rcLabel: "N/A",
						});
					}

					forEach(emplyr.children, (rc) => {
						if (!this.parentChildRelationship[emplyr.id]) {
							this.parentChildRelationship[emplyr.id] = [];
						}
						this.childParentRelationship[rc.id] = emplyr.id;
						this.parentChildRelationship[emplyr.id].push(rc.id);
						const reportingCentre = {
							rcId: rc.id,
							rcLabel: rc.label,
						};
						hierarchiesToAccess.push({
							...parent,
							...employer,
							...reportingCentre,
						});
					});
				});
			}
		});
		// NOTE that hierarchiesToAccess is never changed after this initialisation.
		this.hierarchiesToAccess = hierarchiesToAccess;
	}

	private onClickCheckBox({
		rowIndex,
		row,
		value,
		groupBy,
	}: {
		rowIndex: number;
		row: HierarchiesToAccess;
		value: boolean;
		groupBy: string;
	}): void {
		//employer
		if (groupBy === EMPLOYER) {
			this.addOrRemoveEntity(row, "employerId", value);
		} // parent
		else if (groupBy === PARENT) {
			this.addOrRemoveEntity(row, "parentId", value);
		} // rc
		else if (groupBy === RC) {
			this.addOrRemoveEntity(row, "rcId", value);
		}
		this.isFormDirty = true;
	}

	discardChanges() {
		this.isFormDirty = false;
		this.$router.push({ name: "Users and roles" });
	}

	noRole() {
		this.disableAllCheckboxes = true;
		this.enableOrDisableRoles(false);
		this.applyRolesToEntities = [];
		this.showSponsors = false;
		this.userSponsorIds = [];
	}

	singleRole() {
		const mainRole = this.roleOption(this.userRoleIds[0]);

		const isIressOperations = this.iressOperations;

		if (isIressOperations && this.userDetail.ownRecord) {
			// Iress operations can't edit their own record
			this.disableAllCheckboxes = true;
			this.enableOrDisableRoles(true);
			this.showSponsors = false;
		} else if (isIressOperations && mainRole) {
			if (mainRole.hierarchySelected) {
				const applyRolesToEntities: string[] = [];
				forEach(this.employerHierarchy, (parentOrg) => {
					applyRolesToEntities.push(parentOrg.id);
				});
				this.applyRolesToEntities = [...applyRolesToEntities];
			}
			this.disableAllCheckboxes = !mainRole.hierarchyEnabled;
			this.enableOrDisableRoles(false);
			this.showSponsors = mainRole.showSponsors;
			if (!this.showSponsors) {
				this.userSponsorIds = this.sponsorOptions.map((s) =>
					Number(s.value)
				);
			}
		} else if (mainRole) {
			if (mainRole.hierarchySelectedForEmployer) {
				const applyRolesToEntities: string[] = [];
				forEach(this.employerHierarchy, (parentOrg) => {
					applyRolesToEntities.push(parentOrg.id);
				});
				this.applyRolesToEntities = [...applyRolesToEntities];
			}
			this.disableAllCheckboxes = !mainRole.hierarchyEnabledForEmployer;
		}

		if (!this.isAddPage && (this.isViewPage || !this.canEditRoles)) {
			this.enableOrDisableRoles(true);
		}
	}

	multipleRoles() {
		this.showSponsors =
			this.roleOption(this.userRoleIds[0])?.showSponsors === true;
		if (!this.isAddPage && (this.isViewPage || !this.canEditRoles)) {
			this.enableOrDisableRoles(true);
		}
	}

	get isViewPage() {
		return this.pageMode === "view";
	}

	get isAddPage() {
		return this.pageMode === "add";
	}

	enableOrDisableRoles(disableAll = false) {
		if (this.roleOptions) {
			if (this.pageMode === "view") {
				this.roleOptions.map((role) => {
					role.disabled = disableAll;
				});
				this.roleOptions = [...this.roleOptions];
				this.sponsorOptions.map((s) => {
					s.disabled = disableAll;
				});
				this.sponsorOptions = [...this.sponsorOptions];
				return;
			}
		}
	}

	beforeRouteLeave(
		to: AppRoute,
		from: AppRoute,
		next: AppRouteNextFunction<AddOrEditPortalUsers>
	) {
		if (this.isFormDirty) {
			this.showCancelModal = true;
			next(false);
		} else {
			next();
		}
	}
}
