import { AxiosResponse } from "axios";

import axios from "@/utils/ApiUtils";
import { getFormattedCurrency } from "@/utils/CommonUtils";
import {
	FinalBatchReviewFundRecord,
	ReviewBatch,
} from "@/models/FinalBatchReviewRecord";
import { getReviewAuthorisedUnfundedBatches } from "@/constants/apiconstants";

/**
 * This should correspond to the Java class au.com.iress.clearinghouse.portal.rest.ContributionSummary
 */
export interface ContributionSummary {
	parentBatchId: number;
	filename: string;

	reportingCentres: number;

	employees: number;
	funds: number;

	warnings: number | null;
	errors: number | null;

	batchStatus: string;
	fundStatus: string;

	canCancelBatch: boolean;
	canModifyBatch: boolean;
	batchAuthorised: boolean;
	batchSubmitted: boolean;
	totalDollarValue: string;

	withFileLevelErrorWarning: boolean;
	contributionFileType: string;
	showSplitBatchOption: boolean;
	canSplitBatch: boolean;

	refresh: boolean;
	refreshInterval: number;
	dbBatchValidatedNoErrors: boolean;
}

export default class ContributionResource {
	public static async getContributionSummary(
		parentBatchId: number
	): Promise<AxiosResponse<ContributionSummary>> {
		const resp = await axios.get<ContributionSummary>(
			"api/contribution/summary/" + parentBatchId,
			{
				headers: {
					"Content-Type": "application/json",
				},
			}
		);
		if (resp.data) {
			if (!resp.data.parentBatchId) {
				throw new Error("Unexpected response from server.");
			}
			if (resp.data.employees === undefined) {
				resp.data.employees = 0;
			}
			if (resp.data.reportingCentres === undefined) {
				resp.data.reportingCentres = 0;
			}
			if (resp.data.funds === undefined) {
				resp.data.funds = 0;
			}
			if (resp.data.totalDollarValue === undefined) {
				resp.data.totalDollarValue = "0";
			}
			if (
				resp.data.totalDollarValue &&
				resp.data.totalDollarValue.charAt(0) !== "$"
			) {
				resp.data.totalDollarValue = getFormattedCurrency(
					resp.data.totalDollarValue
				);
			}
		}
		return resp;
	}
}

export async function fetchBatchDataByFund(
	batchId: number,
	fundCode: string
): Promise<FinalBatchReviewFundRecord[]> {
	return axios
		.get<FinalBatchReviewFundRecord[]>(
			`api/contribution/final-batch-review/${batchId}/${fundCode}`
		)
		.then((resp) => resp.data);
}

export async function fetchRelativeParentBatchId(
	parentBatchId: number
): Promise<number> {
	return axios
		.get<number>(`api/contribution/fetch-contribution/${parentBatchId}`)
		.then((resp) => resp.data);
}

export async function fetchReviewAuthorisedUnfundedBatches(
	batchId: number
): Promise<ReviewBatch> {
	return axios
		.get<ReviewBatch>(getReviewAuthorisedUnfundedBatches(batchId))
		.then((resp) => resp.data);
}
