









































































































































import Vue from "vue";
import {
	ColDef,
	ColGroupDef,
	ICellRendererParamsTyped,
	IServerSideDatasourceTyped,
	IServerSideGetRowsParamsTyped,
} from "ag-grid-community";
import { isOnlyOneChildlessEmployerSelected } from "@/utils/EmployerSelectorUtils";
import { NO_RC_ERROR_MESSAGE } from "@/constants/constants";
import axios, { axiosStatic } from "@/utils/ApiUtils";
import { FilterModel, PagedResult, TranslationMapping } from "@/grid/gridTypes";
import {
	bulkFiles,
	deleteBulkFile,
	fileFormatForBulkFiles,
	getBulkFileFunds,
} from "@/constants/apiconstants";
import { toastErrorMessage, toastSuccessMessage } from "@/plugins/toasts";
import { parseErrorMessage } from "@/utils/ErrorUtils";
import { EmployerHierarchy } from "@/store/modules/persistent/persistentTypes";
import { Component, Watch } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import EmployerSelector from "@/components/EmployerSelector.vue";
import Grid from "@/grid/Grid.vue";
import GridFilter from "@/components/GridFilter.vue";
import TextField from "@/form/TextField.vue";
import SelectField from "@/form/SelectField.vue";
import { createNamespacedHelpers } from "vuex";
import BulkFileUploadForm from "@/components/BulkFileUploadForm.vue";
import SubHeading from "@/components/SubHeading.vue";
import {
	columnDateTimeFormatter,
	getDateForPastNMonths,
} from "@/utils/CommonUtils";
import GridActionsRenderer from "@/grid/GridActionsRenderer.vue";
import { hasPermission } from "@/utils/PermissionUtils";
import ModalOrPopup from "@/components/ModalOrPopup.vue";
import Button from "@/form/Button.vue";
import DatepickerField from "@/form/DatepickerField.vue";
import CheckBox from "@/form/CheckBox.vue";
import MultiSelect from "@/form/MultiSelect.vue";
import { SelectOption } from "@/form/FieldOptions";

const { mapState, mapMutations } = createNamespacedHelpers("persistent");

interface BulkFileResponseDto {
	id: number;
	originalFileName: string;
	stored: string;
	status: string;
	actionedBy: number;
	fileType: string;
}

interface FileFormatDto {
	value: string;
	label: string;
}

interface FileFormatResp {
	displayFileFormatOption: boolean;
	fileFormats: FileFormatDto[];
}

@Component({
	components: {
		Layout,
		EmployerSelector,
		Grid,
		GridFilter,
		TextField,
		SelectField,
		BulkFileUploadForm,
		SubHeading,
		ModalOrPopup,
		Button,
		DatepickerField,
		CheckBox,
		MultiSelect,
	},
	computed: mapState(["selectedEntities", "employerHierarchy"]),
	methods: {
		...mapMutations(["setSelectedEntities"]),
	},
})
export default class BulkFilePage
	extends Vue
	implements IServerSideDatasourceTyped
{
	private errorMessage: string | null = null;
	private gridReady = false;

	selectedFileFormatId = "";
	selectedEntities!: string[];
	employerHierarchy!: EmployerHierarchy[];
	private bulkFileForDeletion: BulkFileResponseDto | null = null;
	private readonly filterModel: FilterModel = {
		startDate: {
			value: this.getDefaultStartDate(),
			column: "startDate",
		},
		endDate: {
			value: "",
			column: "endDate",
		},
		status: {
			value: "",
			column: "status",
		},
		showDeletedFiles: {
			value: false,
			column: "showDeletedFiles",
		},
		fileFormatId: {
			value: "",
			column: "fileFormatId",
		},
	};

	readonly statusOptions = [
		{
			label: "All",
			value: "",
		},
		{
			label: "Validated",
			value: "VAL_D",
		},
		{
			label: "Transferring",
			value: "TRAN_G",
		},
		{
			label: "Processed",
			value: "PROC_D",
		},
		{
			label: "Deleted",
			value: "DEL_D",
		},
		{
			label: "Purged",
			value: "PURG_D",
		},
		{
			label: "Fail",
			value: "FAIL",
		},
		{
			label: "Error",
			value: "SYSERR",
		},
	];
	private bulkFileFormatOptions: SelectOption[] = [];
	private bulkFileFunds: SelectOption[] = [];
	selectedBulkFileFund = "";

	private showDeleteForm = false;
	fileUploadKey = 0;
	private readonly bulkFileFormat: SelectOption | null = null;
	private fileFormatFilter: SelectOption[] = [];
	public $refs!: {
		gridEl: Grid;
	};

	private gridVMList: Vue[] = [];

	private onGridReady() {
		this.gridReady = true;
	}

	/**
	 * Type the mapped persistent.setSelectedEntities mutation.
	 */
	setSelectedEntities!: (selectedEntities: string[]) => void;

	private readonly columnDefs: (ColGroupDef | ColDef)[] = [
		{
			headerName: "Unique ID",
			field: "id",
			resizable: true,
			width: 80,
		},
		{
			headerName: "File name",
			field: "originalFileName",
			resizable: true,
		},
		{
			headerName: "File format",
			field: "fileFormatName",
			resizable: true,
		},
		{
			headerName: "File category",
			field: "fileCategory",
			resizable: true,
		},
		{
			headerName: "Date submitted",
			field: "stored",
			resizable: true,
			valueFormatter: columnDateTimeFormatter,
		},
		{
			headerName: "Actioned by",
			field: "actionedBy",
			resizable: true,
		},
		{
			headerName: "Status",
			field: "status",
			resizable: true,
			width: 100,
			cellRendererParams: {
				translationMappings: [
					{
						value: "PROC_D",
						translatedValue: "Processed",
						style: "font-weight: bold;color:#228b22;", //Forest green
					},
					{
						value: "FAIL",
						translatedValue: "Fail",
						style: "font-weight: bold;color:#8b0000;", //Red
					},
					{
						value: "SYSERR",
						translatedValue: "Error",
						style: "font-weight: bold;color:#8b0000;", //Red
					},
					{
						value: "DEL_D",
						translatedValue: "Deleted",
						style: "font-weight: bold;",
					},
					{
						value: "PURG_D",
						translatedValue: "Purged",
						style: "font-weight: bold;",
					},
					{
						value: "VAL_D",
						translatedValue: "Validated",
						style: "font-weight: bold;color:#228b22;", //Forest green
					},
					{
						value: "TRAN_G",
						translatedValue: "Transferring",
						style: "font-weight: bold;color:#228b22;", //Forest green
					},
				],
			},
			cellRenderer: "valueTranslatedCellRenderer",
		},
		{
			headerName: "Delete",
			field: "__Actions",
			cellRenderer: this.actionsRender,
			pinned: "right",
			maxWidth: 140,
		},
	];

	actionsRender(
		params: ICellRendererParamsTyped<BulkFileResponseDto>
	): HTMLElement {
		const vm = new Vue({
			el: document.createElement("div"),
			render: (createElement) => {
				return createElement(GridActionsRenderer, {
					props: {
						rowIndex: params.rowIndex,
						row: params.data,
						isEdit: false,
						isDelete: true,
						showDeleteDisabled:
							!hasPermission("DELETE_BULK_FILE") ||
							params.data.status === "SUCCES",
					},
					on: {
						clickDelete: this.onDeleteItem,
					},
				});
			},
		});
		this.gridVMList.push(vm);
		return vm.$el as HTMLElement;
	}

	private onDeleteItem({
		rowIndex,
		row,
	}: {
		rowIndex: number;
		row: BulkFileResponseDto;
	}) {
		this.bulkFileForDeletion = row;
		this.showDeleteForm = true;
	}

	created() {
		const selectEntityParam = this.$route.query.selectEntity as string;
		if (selectEntityParam && selectEntityParam !== "") {
			this.setSelectedEntities([selectEntityParam]);
		}
		this.onSelectedReportingCenterChanged();
	}

	get selectedReportingCenter() {
		return this.$store.state.persistent.selectedEntities;
	}

	@Watch("selectedReportingCenter")
	onSelectedReportingCenterChanged() {
		this.reloadGrid();
		this.reloadBulkFileFormatOptions();
		this.reloadFunds();
	}

	private reloadBulkFileFormatOptions() {
		// clear the file format filter and the options
		this.fileFormatFilter = [];
		this.bulkFileFormatOptions = [];

		if (this.selectedEntities.length != 0) {
			axios
				.get<SelectOption[]>(
					fileFormatForBulkFiles(this.selectedEntities[0])
				)
				.then((resp) => {
					this.bulkFileFormatOptions = resp.data;
					if (
						this.bulkFileFormatOptions.length === 1 &&
						this.bulkFileFormatOptions[0]
					) {
						this.selectedFileFormatId =
							this.bulkFileFormatOptions[0].value;
					} else {
						this.selectedFileFormatId = "";
					}
				});
		}
	}

	private reloadFunds() {
		if (this.selectedEntities.length != 0) {
			axios
				.get<SelectOption[]>(getBulkFileFunds(this.selectedEntities[0]))
				.then((resp) => {
					this.bulkFileFunds = resp.data;
					if (this.bulkFileFunds.length === 1) {
						this.selectedBulkFileFund = this.bulkFileFunds[0].value;
					} else {
						this.selectedBulkFileFund = "";
					}
				});
		}
	}

	updateFilterFileFormatName(value: TranslationMapping) {
		if (value && value.value) {
			this.filterModel.fileFormatId.value = value.value;
		} else {
			this.filterModel.fileFormatId.value = "";
		}
		this.onApplyFilter();
	}

	private filterFileNameLabel(option: { label: string }): string {
		return option.label;
	}

	get filterByFileFormatPlaceholder() {
		return this.bulkFileFormatOptions.length === 0
			? "Please select a parent or an employer that is associated to a file format first"
			: "Type to search by the file format";
	}

	private onApplyFilter() {
		// commitToModule(this.$store, this.vuexStore, this.filterModel);
		this.$refs.gridEl.reload();
	}

	onResetFilter() {
		this.filterModel.startDate.value = this.getDefaultStartDate();
		this.filterModel.endDate.value = "";
		this.filterModel.status.value = "";
		this.filterModel.showDeletedFiles.value = false;
		this.filterModel.fileFormatId.value = "";

		this.onApplyFilter();
	}

	private getDefaultStartDate(): string {
		return getDateForPastNMonths(6);
	}

	private reloadGrid(): void {
		this.gridReady = false;
		if (!this.$refs.gridEl) {
			return;
		}
		this.$refs.gridEl.reload();
		this.gridReady = true;
	}

	private reloadGridAndUploadForm() {
		this.reloadGrid();
		this.fileUploadKey++;
	}

	getRows(params: IServerSideGetRowsParamsTyped<BulkFileResponseDto>): void {
		if (
			isOnlyOneChildlessEmployerSelected(
				this.selectedEntities,
				this.employerHierarchy
			)
		) {
			this.errorMessage = NO_RC_ERROR_MESSAGE;
			params.successCallback([]);
			return;
		}

		params.request.filterModel = Object.keys(this.filterModel).map(
			(key) => {
				return this.filterModel[key];
			}
		);

		axios
			.get<PagedResult<BulkFileResponseDto>>(bulkFiles(), {
				params: {
					// Pass in <Grid> parameters
					grid: params.request,
					entities: this.$store.state.persistent.selectedEntities,
				},
				cancelToken: params.cancelToken,
			})
			.then((response) => {
				params.successCallback(response.data);
			})
			.catch((error) => {
				if (axiosStatic.isCancel(error)) {
					return;
				}
				toastErrorMessage(parseErrorMessage(error));
			});
	}

	private onDeleteConfirmation() {
		if (this.bulkFileForDeletion) {
			axios
				.delete(deleteBulkFile(this.bulkFileForDeletion.id))
				.then(() => {
					toastSuccessMessage("File has been deleted successfully.");
					this.reloadGrid();
				})
				.catch((error) => {
					toastErrorMessage(parseErrorMessage(error));
				})
				.finally(() => (this.showDeleteForm = false));
		}
	}

	clearErrorMessage() {
		this.errorMessage = null;
	}
}
