






import Vue from "vue";
import { Component } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import axios from "@/utils/ApiUtils";
import { filesFormsDownload, releaseNotes } from "@/constants/apiconstants";
import { parseErrorMessage } from "@/utils/ErrorUtils";
import { toastErrorMessage } from "@/plugins/toasts";
import Grid from "@/grid/Grid.vue";
import {
	ColDef,
	ColGroupDef,
	ICellRendererParamsTyped,
} from "ag-grid-community";
import { columnDateFormatter } from "@/utils/CommonUtils";

interface ReleaseNotes {
	filename: string;
	releaseDate: string;
}

@Component({
	components: {
		Layout,
		Grid,
	},
})
export default class ReleaseNotesPage extends Vue {
	private releaseNotes: string[] = [];

	linkCellRenderer = (params: ICellRendererParamsTyped<ReleaseNotes>) => {
		const spanElement = document.createElement("span");
		spanElement.innerHTML = `<a href="#">${params.value}</a>`;
		spanElement.addEventListener("click", ($event) => {
			$event.preventDefault();
			this.onDownloadDocument(params.value);
		});
		return spanElement;
	};

	private readonly columnDefs: (ColGroupDef | ColDef)[] = [
		{
			headerName: "File",
			field: "filename",
			width: 500,
			cellRenderer: this.linkCellRenderer,
		},
		{
			headerName: "Release date",
			field: "releaseDate",
			width: 100,
			valueFormatter: columnDateFormatter,
		},
	];

	mounted() {
		axios
			.get(releaseNotes())
			.then((response) => {
				this.releaseNotes = response.data;
			})
			.catch((error) => {
				toastErrorMessage(parseErrorMessage(error));
			});
	}

	private onDownloadDocument(filename: string) {
		axios
			.get(filesFormsDownload(filename), {
				responseType: "blob",
			})
			.then((response) => {
				const blob = new Blob([response.data]);
				if (window.navigator && window.navigator.msSaveBlob) {
					window.navigator.msSaveBlob(blob, filename);
				} else {
					const url = window.URL.createObjectURL(blob);
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", filename);
					link.click();
				}
			})
			.catch((error) => {
				toastErrorMessage(parseErrorMessage(error));
			});
	}
}
