var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container login-container container-top-buffer"},[_c('div',{staticClass:"login"},[_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
					_vm.phase === 'LOGIN_CRED_DEFAULT' ||
					_vm.phase == 'LOGIN_CRED_ERROR'
				),expression:"\n\t\t\t\t\tphase === 'LOGIN_CRED_DEFAULT' ||\n\t\t\t\t\tphase == 'LOGIN_CRED_ERROR'\n\t\t\t\t"}],staticClass:"cred"},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
				var untouched = ref.untouched;
				var failed = ref.failed;
return [_c('form',{staticClass:"card-body"},[_c('ValidationProvider',{attrs:{"name":"Username","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
				var errors = ref.errors;
return _c('div',{},[_c('div',{class:_vm.errorClass(errors)},[_c('label',{attrs:{"for":"username"}},[_c('span',{staticClass:"text-danger"},[_vm._v(" * ")]),_vm._v("Username")]),(errors.length > 0)?_c('small',{staticClass:"validation-error-message"},[_vm._v(_vm._s(errors[0]))]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.username),expression:"username"}],staticClass:"form-control",attrs:{"data-cy":"loginUsername","type":"text","id":"username"},domProps:{"value":(_vm.username)},on:{"input":function($event){if($event.target.composing){ return; }_vm.username=$event.target.value}}})])])}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
				var errors = ref.errors;
return [_c('div',{class:_vm.errorClass(errors)},[_c('label',{attrs:{"for":"username"}},[_c('span',{staticClass:"text-danger"},[_vm._v(" * ")]),_vm._v("Password")]),(errors.length > 0)?_c('small',{staticClass:"validation-error-message"},[_vm._v(_vm._s(errors[0]))]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control",attrs:{"data-cy":"loginPassword","type":"password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}})])]}}],null,true)}),(_vm.errorMessage)?_c('div',{staticClass:"error-block"},[_c('small',{staticClass:"error-message",attrs:{"data-cy":"loginError"}},[_c('ErrorIcon'),_vm._v(" "+_vm._s(_vm.errorMessage))],1)]):_vm._e(),_c('div',{staticClass:"pt-4"},[_c('div',{staticClass:"form-group"},[_c('Button',{attrs:{"block":"","size":"lg","type":"submit","data-cy":"loginSubmit","disabled":untouched || failed},on:{"click":_vm.login}},[_vm._v(" Login ")])],1),_c('div',{staticClass:"form-group"},[_c('Button',{attrs:{"block":"","size":"lg","data-cy":"forgotPassword"},on:{"click":_vm.showForgotPassword}},[_vm._v(" Forgot Password ")])],1)])],1)]}}])})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
					_vm.phase === 'LOGIN_TWOFA_DEFAULT' ||
					_vm.phase === 'LOGIN_TWOFA_ERROR'
				),expression:"\n\t\t\t\t\tphase === 'LOGIN_TWOFA_DEFAULT' ||\n\t\t\t\t\tphase === 'LOGIN_TWOFA_ERROR'\n\t\t\t\t"}],staticClass:"twofa"},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
				var pristine = ref.pristine;
				var failed = ref.failed;
return [_c('form',[_c('div',{staticClass:"card-body"},[_c('b-row',[_c('b-col',{staticClass:"icon",attrs:{"align-h":"center"}},[_c('font-awesome-layers',{staticClass:"fa-6x",attrs:{"data-cy":"twofaIcon","full-width":""}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'mobile']}}),_c('font-awesome-icon',{attrs:{"icon":['fal', 'comment-dots'],"transform":"shrink-13 up-1"}})],1)],1)],1),_c('p',[_vm._v(" We sent you a code via SMS. Type it below to verify your identity. ")]),_c('ValidationProvider',{attrs:{"name":"Code","rules":("" + (_vm.phase === 'LOGIN_TWOFA_DEFAULT' ||
									_vm.phase === 'LOGIN_TWOFA_ERROR'
										? 'required|digits:6'
										: ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
								var errors = ref.errors;
return [_c('div',{class:_vm.errorClass(errors)},[(errors.length > 0)?_c('small',{staticClass:"validation-error-message"},[_vm._v(" "+_vm._s(errors[0]))]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.code),expression:"code"}],staticClass:"form-control",attrs:{"data-cy":"loginTwoFa","required":"","type":"text","maxlength":"6"},domProps:{"value":(_vm.code)},on:{"input":function($event){if($event.target.composing){ return; }_vm.code=$event.target.value}}})])]}}],null,true)}),_c('div',{staticClass:"resend-link"},[_c('Button',{attrs:{"variant":"link"},on:{"click":_vm.login}},[_vm._v(" Resend code ")])],1),(_vm.shouldShowFinancialDocsReadCheckbox)?_c('CheckBox',{attrs:{"name":"documentReadConfirmationCheckbox","data-cy":"documentReadConfirmation","rules":{ required: { allowFalse: false } }},model:{value:(_vm.financialDocsRead),callback:function ($$v) {_vm.financialDocsRead=$$v},expression:"financialDocsRead"}},[_c('span',{staticClass:"text-danger"},[_vm._v("* ")]),_vm._v("I confirm that I have read the "),_c('a',{attrs:{"href":"#"},on:{"click":_vm.downloadProductDisclosureStatementFinancialServicesGuide}},[_vm._v("Iress SuperConnector Combined Financial Service Guide and Product Disclosure Statement")]),_vm._v(", and that I understand and agree to the terms and conditions set out in that document.")]):_vm._e(),(_vm.errorMessage)?_c('div',{staticClass:"error-block"},[_c('small',{staticClass:"error-message",attrs:{"data-cy":"loginError"}},[_c('ErrorIcon'),_vm._v(" "+_vm._s(_vm.errorMessage))],1)]):_vm._e()],1),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"form-group"},[_c('Button',{attrs:{"block":"","type":"submit","size":"lg","data-cy":"loginTwoFaButton","disabled":pristine || failed},on:{"click":_vm.loginTwoFA}},[_vm._v(" Verify ")])],1)])])]}}])})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
					_vm.phase === 'LOGIN_FORGOT_PASSWORD_DEFAULT' ||
					_vm.phase === 'LOGIN_FORGOT_PASSWORD_ERROR'
				),expression:"\n\t\t\t\t\tphase === 'LOGIN_FORGOT_PASSWORD_DEFAULT' ||\n\t\t\t\t\tphase === 'LOGIN_FORGOT_PASSWORD_ERROR'\n\t\t\t\t"}]},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
				var untouched = ref.untouched;
				var failed = ref.failed;
return [_c('form',[_c('div',{staticClass:"card-body"},[_c('ValidationProvider',{attrs:{"mode":"lazy","name":"Email Address","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
				var errors = ref.errors;
return [_c('div',{class:_vm.errorClass(errors)},[_c('label',{attrs:{"for":"emailaddress"}},[_c('span',{staticClass:"text-danger"},[_vm._v(" * ")]),_vm._v("Email Address")]),(errors.length > 0)?_c('small',{staticClass:"validation-error-message"},[_vm._v(_vm._s(errors[0]))]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-control",attrs:{"data-cy":"resetPasswordEmail","autocapitalize":"off","autocomplete":"email","name":"email","type":"text","placeholder":"Email Address"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Mobile Phone","rules":"required|digits:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
				var errors = ref.errors;
return [_c('div',{class:_vm.errorClass(errors)},[_c('label',{attrs:{"for":"phone"}},[_c('span',{staticClass:"text-danger"},[_vm._v(" * ")]),_vm._v("Mobile Phone")]),(errors.length > 0)?_c('small',{staticClass:"validation-error-message"},[_vm._v(_vm._s(errors[0]))]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phone),expression:"phone"}],staticClass:"form-control",attrs:{"data-cy":"resetPasswordPhone","autocapitalize":"off","autocomplete":"phone","name":"phone","type":"text","placeholder":"Mobile Phone"},domProps:{"value":(_vm.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.phone=$event.target.value}}})])]}}],null,true)}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(_vm.errorMessage),expression:"errorMessage"}],staticClass:"error-message"},[_c('ErrorIcon'),_vm._v(" "+_vm._s(_vm.errorMessage))],1)],1),_c('div',{staticClass:"card-footer"},[_c('Button',{attrs:{"variant":"link","data-cy":"backToLogin"},on:{"click":_vm.backToLogin}},[_vm._v(" Back to Login ")]),(
									_vm.phase ===
										'LOGIN_FORGOT_PASSWORD_DEFAULT' ||
									_vm.phase === 'LOGIN_FORGOT_PASSWORD_ERROR'
								)?_c('vue-recaptcha',{ref:"refPasswordResetInitiateCaptcha",attrs:{"loadRecaptchaScript":"","sitekey":_vm.recaptchaSiteKey,"size":"invisible"},on:{"error":_vm.recaptchaError,"verify":_vm.setRecaptcha,"expired":_vm.expireRecaptcha}}):_vm._e(),_c('Button',{attrs:{"block":"","size":"lg","data-cy":"resetPasswordSendCode","disabled":!_vm.canRequestPassword ||
									untouched ||
									failed,"type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.executeRecaptcha.apply(null, arguments)}}},[_vm._v(" Send ")])],1)])]}}])})],1),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
									var untouched = ref.untouched;
									var failed = ref.failed;
return [_c('form',{directives:[{name:"show",rawName:"v-show",value:(
						_vm.phase === 'LOGIN_RESET_PASSWORD_DEFAULT' ||
						_vm.phase === 'LOGIN_RESET_PASSWORD_ERROR' ||
						_vm.phase === 'RESET_PASSWORD_INVALID_BRAND' ||
						_vm.phase === 'RESET_PASSWORD_SUCCESS'
					),expression:"\n\t\t\t\t\t\tphase === 'LOGIN_RESET_PASSWORD_DEFAULT' ||\n\t\t\t\t\t\tphase === 'LOGIN_RESET_PASSWORD_ERROR' ||\n\t\t\t\t\t\tphase === 'RESET_PASSWORD_INVALID_BRAND' ||\n\t\t\t\t\t\tphase === 'RESET_PASSWORD_SUCCESS'\n\t\t\t\t\t"}]},[_c('div',{staticClass:"card-body"},[_c('ValidationProvider',{attrs:{"name":"New Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
					var errors = ref.errors;
return [_c('div',{class:_vm.errorClass(errors)},[_c('label',{attrs:{"for":"newPassword"}},[_c('span',{staticClass:"text-danger"},[_vm._v(" * ")]),_vm._v("New Password")]),(errors.length > 0)?_c('small',{staticClass:"validation-error-message"},[_vm._v(_vm._s(errors[0]))]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newPassword),expression:"newPassword"}],staticClass:"form-control",attrs:{"data-cy":"resetPasswordNewPassword","autocapitalize":"off","autocomplete":"password","name":"newPassword","type":"password","placeholder":"New Password"},domProps:{"value":(_vm.newPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.newPassword=$event.target.value}}})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Re-enter New Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
					var errors = ref.errors;
return [_c('div',{class:_vm.errorClass(errors)},[_c('label',{attrs:{"for":"confirmPassword"}},[_c('span',{staticClass:"text-danger"},[_vm._v(" * ")]),_vm._v("Re-enter New Password")]),(errors.length > 0)?_c('small',{staticClass:"validation-error-message"},[_vm._v(_vm._s(errors[0]))]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirmNewPassword),expression:"confirmNewPassword"}],staticClass:"form-control",attrs:{"data-cy":"resetPasswordConfirmPassword","autocapitalize":"off","autocomplete":"password","name":"confirmNewPassword","type":"password","placeholder":"Re-enter New Password"},domProps:{"value":(_vm.confirmNewPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.confirmNewPassword=$event.target.value}}})])]}}],null,true)}),_c('password-strength-bar',{attrs:{"password":_vm.newPassword}}),_c('ValidationProvider',{attrs:{"name":"Code","rules":"required|digits:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
					var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('small',{staticClass:"resetpassword-twofa"},[_vm._v("If the details entered match a valid account, you should receive a PIN to your registered mobile number. Enter the PIN below to verify your identity.")]),_c('div',{class:_vm.errorClass(errors)},[(errors.length > 0)?_c('small',{directives:[{name:"show",rawName:"v-show",value:(_vm.errorMessage),expression:"errorMessage"}],staticClass:"validation-error-message"},[_vm._v(_vm._s(errors[0]))]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.resetpassword2FA),expression:"resetpassword2FA"}],staticClass:"form-control",attrs:{"data-cy":"resetPasswordTwoFaCode","type":"text","maxlength":"6","for-input":"inputResetPasswordTwoFA","placeholder":"Enter Code"},domProps:{"value":(_vm.resetpassword2FA)},on:{"input":function($event){if($event.target.composing){ return; }_vm.resetpassword2FA=$event.target.value}}})])])]}}],null,true)}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.errorMessage),expression:"errorMessage"}],staticClass:"error-message",attrs:{"data-cy":"resetPasswordError"}},[_c('ErrorIcon'),_vm._v(" "+_vm._s(_vm.errorMessage)+" ")],1),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.phase === 'RESET_PASSWORD_SUCCESS'),expression:"phase === 'RESET_PASSWORD_SUCCESS'"}],staticClass:"success-message",attrs:{"data-cy":"resetPasswordSuccess"}},[_vm._v(" Your password has been updated as requested. You will now be taken to the login page. ")]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(
								_vm.phase === 'RESET_PASSWORD_INVALID_BRAND'
							),expression:"\n\t\t\t\t\t\t\t\tphase === 'RESET_PASSWORD_INVALID_BRAND'\n\t\t\t\t\t\t\t"}],staticClass:"warning-message",attrs:{"data-cy":"resetPasswordInvalid"}},[_c('warning-icon'),_vm._v(" You will now be redirected to the correct login page. Please click on Forgot Password on that page to reset your password. ")],1)],1),_c('div',{staticClass:"card-footer"},[(
								_vm.phase === 'LOGIN_RESET_PASSWORD_DEFAULT' ||
								_vm.phase === 'LOGIN_RESET_PASSWORD_ERROR' ||
								_vm.phase === 'RESET_PASSWORD_SUCCESS'
							)?_c('vue-recaptcha',{ref:"refPasswordResetInitiateCaptcha",attrs:{"loadRecaptchaScript":"","sitekey":_vm.recaptchaSiteKey,"size":"invisible"},on:{"error":_vm.recaptchaError,"verify":_vm.setRecaptcha,"expired":_vm.expireRecaptcha}}):_vm._e(),(!_vm.accountLocked)?_c('Button',{attrs:{"data-cy":"resendCode","variant":"link","disabled":!_vm.canRequestPassword},on:{"click":function($event){$event.preventDefault();return _vm.executeRecaptcha.apply(null, arguments)}}},[_vm._v(" Resend code ")]):_vm._e(),(!_vm.accountLocked)?_c('Button',{attrs:{"block":"","size":"lg","type":"submit","data-cy":"resetPasswordButton","disabled":untouched || failed},on:{"click":_vm.resetPassword}},[_vm._v(" Reset Password ")]):_vm._e()],1)])]}}])})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header"},[_c('div',[_c('div',{staticClass:"logo",attrs:{"data-cy":"logo"}}),_c('h5',[_vm._v("SuperConnector Employer Portal")])])])}]

export { render, staticRenderFns }