import { ContributionRow } from "@/models/ContributionRow";
import { ValueGetterParamsTyped } from "ag-grid-community";

const getOtherAmounts = (
	params: ValueGetterParamsTyped<ContributionRow>
): number => {
	const record = params.data;
	let res = 0;
	// NOTE(Jae): 2020-07-15
	// There might be precision problems with the currency amounts being added in this manner.
	// We might want to swap to something like this if that ends up being the case:
	// https://github.com/royNiladri/js-big-decimal
	//
	// For now, this approach is good enough.
	if (record.employerContributionsVoluntary) {
		res += Number(record.employerContributionsVoluntary);
	}
	if (record.personalContributions) {
		res += Number(record.personalContributions);
	}
	if (record.spouseContributions) {
		res += Number(record.spouseContributions);
	}
	if (record.childContributions) {
		res += Number(record.childContributions);
	}
	if (record.otherThirdPartyContributions) {
		res += Number(record.otherThirdPartyContributions);
	}
	return res;
};

const getDbAmounts = (
	params: ValueGetterParamsTyped<ContributionRow>
): number => {
	const record = params.data;
	let res = 0;

	if (record.definedBenefitMemberPreTaxContribution) {
		res += Number(record.definedBenefitMemberPreTaxContribution);
	}
	if (record.definedBenefitMemberPostTaxContribution) {
		res += Number(record.definedBenefitMemberPostTaxContribution);
	}
	if (record.definedBenefitEmployerContribution) {
		res += Number(record.definedBenefitEmployerContribution);
	}
	if (record.definedBenefitNotionalMemberPreTaxContribution) {
		res += Number(record.definedBenefitNotionalMemberPreTaxContribution);
	}
	if (record.definedBenefitNotionalMemberPostTaxContribution) {
		res += Number(record.definedBenefitNotionalMemberPostTaxContribution);
	}
	if (record.definedBenefitNotionalEmployerContribution) {
		res += Number(record.definedBenefitNotionalEmployerContribution);
	}

	return res;
};

export const getFullname = (
	params: ValueGetterParamsTyped<ContributionRow>
): string => {
	// trimming givenNames and surname to avoid un-necessary spaces withing fullname
	return [params.data.givenNames?.trim(), params.data.surname?.trim()]
		.join(" ")
		.trim();
};

export const getTotalAmount = (
	params: ValueGetterParamsTyped<ContributionRow>
): string => {
	const record = params.data;
	let res = Number(0);
	// NOTE(Jae): 2020-07-15
	// There might be precision problems with the currency amounts being added in this manner.
	// We might want to swap to something like this if that ends up being the case:
	// https://github.com/royNiladri/js-big-decimal
	//
	// For now, this approach is good enough.
	if (record.employerContributionsSuperannuationGuarantee) {
		res += Number(record.employerContributionsSuperannuationGuarantee);
	}
	if (record.employerContributionsSalarySacrificed) {
		res += Number(record.employerContributionsSalarySacrificed);
	}
	if (record.employerContributionsAwardOrProductivity) {
		res += Number(record.employerContributionsAwardOrProductivity);
	}
	res += getOtherAmounts(params);

	res += getDbAmounts(params);
	return res.toFixed(2);
};
