



































import Vue from "vue";
import { Component } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import axios from "@/utils/ApiUtils";
import { employerRegistrationListApi } from "@/constants/apiconstants";
import { parseErrorMessage } from "@/utils/ErrorUtils";
import { toastErrorMessage } from "@/plugins/toasts";
import Grid from "@/grid/Grid.vue";
import { FilterModel, PagedResult } from "@/grid/gridTypes";
import {
	ColDef,
	ICellRendererParamsTyped,
	IServerSideDatasourceTyped,
	IServerSideGetRowsParamsTyped,
} from "ag-grid-community";
import { EmployerRegistration } from "@/models/EmployerRegistration";
import GridActionsRenderer from "@/grid/GridActionsRenderer.vue";
import GridFilter from "@/components/GridFilter.vue";
import SelectField from "@/form/SelectField.vue";
import { SelectOption } from "@/form/FieldOptions";
import Button from "@/form/Button.vue";
import { commitToModule, registerModule } from "@/store/modules/filters";

export const statusOptions: SelectOption[] = [
	{
		label: "Pending only",
		value: "PENDING",
	},
	{
		label: "Approved only",
		value: "APPROVED",
	},
	{
		label: "Created only",
		value: "CREATED",
	},
	{
		label: "Error - Employer ABN exists only",
		value: "E_EABN",
	},
	{
		label: "Error - Username exists only",
		value: "E_USER",
	},
	{
		label: "Error - Unknown BSB only",
		value: "E_BSB",
	},
	{
		label: "Error - Unknown only",
		value: "E_OTHER",
	},
	{
		label: "All",
		value: "",
	},
];

@Component({
	components: {
		Layout,
		Grid,
		GridFilter,
		SelectField,
		Button,
	},
})
export default class EmployerRegistrationMaintenance
	extends Vue
	implements IServerSideDatasourceTyped
{
	statusOptions!: SelectOption[];

	// private selectedStatus = "";
	private gridReady = false;
	private vuexStore = "maintenanceEmployerRegistrationPage";

	public $refs!: {
		gridEl: Grid;
	};

	private filterModel: FilterModel = {
		selectedStatus: {
			value: "",
			column: "selectStatus",
		},
	};

	private readonly columnDefs: ColDef[] = [
		{
			headerName: "Access Code",
			field: "invitationCode",
			minWidth: 100,
			resizable: true,
		},
		{
			headerName: "Name",
			field: "name",
			minWidth: 150,
			resizable: true,
			valueGetter: (params) =>
				params.data
					? `${params.data.authorizedContactFirstName} ${params.data.authorizedContactLastName}`
					: "",
		},
		{
			headerName: "Position",
			field: "authorizedContactPosition",
			minWidth: 120,
			resizable: true,
		},
		{
			headerName: "Registered Name",
			field: "registeredName",
			minWidth: 200,
			resizable: true,
		},
		{
			headerName: "Trading Name",
			field: "tradingName",
			minWidth: 200,
			resizable: true,
		},
		{
			headerName: "Suburb",
			field: "suburb",
			minWidth: 130,
			resizable: true,
		},
		{
			headerName: "State",
			field: "state",
			minWidth: 30,
			resizable: true,
		},
		{
			headerName: "Status",
			field: "status",
			minWidth: 230,
			resizable: true,
			cellRendererParams: {
				translationMappings: [
					{
						value: "PENDING",
						translatedValue: "Pending",
						style: "font-weight: bold;color:#0a5a9f;",
					},
					{
						value: "DECLINED",
						translatedValue: "Declined",
						style: "font-weight: bold;color:#8b0000;",
					},
					{
						value: "APPROVED",
						translatedValue: "Approved",
						style: "font-weight: bold;color:#3cb371;",
					},
					{
						value: "CREATED",
						translatedValue: "Created",
						style: "font-weight: bold;color:#228b22;",
					},
					{
						value: "E_EABN",
						translatedValue: "Error - Employer ABN exists",
						style: "font-weight: bold;color:#8b0000;",
					},
					{
						value: "E_USER",
						translatedValue: "Error - Username exists",
						style: "font-weight: bold;color:#8b0000;",
					},
					{
						value: "E_BSB",
						translatedValue: "Error - Unknown BSB",
						style: "font-weight: bold;color:#8b0000;",
					},
					{
						value: "E_OTHER",
						translatedValue: "Error - Unknown",
						style: "font-weight: bold;color:#8b0000;",
					},
				],
			},
			cellRenderer: "valueTranslatedCellRenderer",
		},
	];

	actionsRender(
		params: ICellRendererParamsTyped<EmployerRegistration>
	): HTMLElement {
		const vm = new Vue({
			el: document.createElement("div"),
			render: (h) => {
				return h(GridActionsRenderer, {
					props: {
						rowIndex: params.rowIndex,
						row: params.data,
						isEdit: false,
						isView: true,
					},
					on: {
						clickView: this.handleViewRow,
					},
				});
			},
		});

		return vm.$el as HTMLElement;
	}

	handleViewRow({ row }: { row: EmployerRegistration }) {
		this.$router.push({
			name: "Review Employer Registration",
			params: {
				employerRegistrationId: String(row.id),
			},
		});
	}

	private onGridReady() {
		this.gridReady = true;
	}

	private reloadGrid(): void {
		this.gridReady = false;
		if (!this.$refs.gridEl) {
			return;
		}
		this.$refs.gridEl.reload();
		this.gridReady = true;
	}

	getRows(params: IServerSideGetRowsParamsTyped<EmployerRegistration>): void {
		const requestParams: any = {
			grid: params.request,
		};
		if (this.filterModel.selectedStatus.value !== "") {
			requestParams.status = this.filterModel.selectedStatus.value;
		}

		axios
			.get<PagedResult<EmployerRegistration>>(
				employerRegistrationListApi,
				{
					params: requestParams,
					headers: {
						"Content-Type": "application/json",
					},
				}
			)
			.then((response) => {
				const pagedResultData = response.data;
				params.successCallback(pagedResultData);
			})
			.catch((error) => {
				toastErrorMessage(parseErrorMessage(error));
			});
	}

	private onResetFilter() {
		this.filterModel.selectedStatus.value = "";
		this.onApplyFilter();
	}

	private onApplyFilter() {
		commitToModule(this.$store, this.vuexStore, this.filterModel);
		this.$refs.gridEl.reload();
	}

	created() {
		this.statusOptions = statusOptions;

		this.columnDefs.push({
			headerName: "Actions",
			cellRenderer: this.actionsRender,
			minWidth: 85,
			resizable: false,
			pinned: "right",
		});

		registerModule(this.$store, this.vuexStore, this.filterModel);
		this.filterModel = this.$store.getters[`${this.vuexStore}/filters`];
	}
}
