
















































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import axios from "@/utils/ApiUtils";
import {
	toastErrorMessage,
	toastInfoMessage,
	toastSuccessMessage,
} from "@/plugins/toasts";
import { parseErrorMessage } from "@/utils/ErrorUtils";
import {
	saffColumnOptions,
	getAllApraFunds,
	createFundFormatAndMappings,
	mappingsByFundFormatId,
	fundFormatAndMappingsById,
} from "@/constants/apiconstants";
import Grid from "@/grid/Grid.vue";
import Button from "@/form/Button.vue";
import {
	ColDef,
	ColGroupDef,
	ICellRendererParamsTyped,
} from "ag-grid-community";
import GridActionsRenderer from "@/grid/GridActionsRenderer.vue";
import { hasPermission } from "@/utils/PermissionUtils";

import ModalOrPopup from "@/components/ModalOrPopup.vue";
import TextField from "@/form/TextField.vue";
import SelectField from "@/form/SelectField.vue";
import {
	ApraResponse,
	FundFormat,
	Fund,
	Status,
	SaveObject,
	FundFormatAndMappingResponse,
} from "./FundFormatAndMapping";
import { SelectOption } from "@/form/FieldOptions";

@Component({
	components: { Layout, Grid, Button, TextField, ModalOrPopup, SelectField },
})
export default class USIMappingDetailsPage extends Vue {
	@Prop(String) fundFormatId!: string;
	private errorMessage: string | null = null;
	private funds: Fund[] = [];

	updateRowStatus(params: any) {
		if (params.data.status !== Status.NEW) {
			params.data.status = Status.UPDATED;
		}
	}

	public $refs!: { gridEl: Grid };
	@Prop(String) usiFormatId!: string;
	private readonly gridVMList: Vue[] = [];
	private showGrid = false;
	private gridReady = false;
	private deleteConfirmation = false;
	private rowForDeletion: Fund | null = null;
	private add = false;
	private saffColumnOptions: { name: string; type: string }[] = [];
	private readonly matchNumber = new RegExp("^[0-9]*$");

	//this is to give every new row a unique id. the id will be sent as 0 to the backend to mark it as a new row.
	private addId = -1;
	private readonly apraFunds: SelectOption[] = [];
	private readonly editAllowed = hasPermission("EDIT_FILE_MAPPING");
	private fundFormat: FundFormat | null = null;

	private readonly columnDefs: (ColGroupDef | ColDef)[] = [
		{
			headerName: "ID",
			field: "id",
			width: 60,
			resizable: true,
			hide: true,
		},
		{
			headerName: "SAFF column",
			field: "saffColumn",
			cellEditor: "agRichSelectCellEditor",
			cellEditorParams: this.getSaffColumnOptions.bind(this),
			valueSetter: (params) => {
				if (params.newValue) {
					params.data.saffColumn = params.newValue;
					return true;
				}
				return false;
			},
			resizable: true,
			editable: this.editAllowed,
			cellClassRules: {
				"border border-danger": (params: any) => {
					return (
						!params.value ||
						params.value === "" ||
						params.value === "-" ||
						this.isSaffDuplicate(params)
					);
				},
			},
			onCellValueChanged: (params: any) => {
				this.updateRowStatus(params);
			},
		},
		{
			headerName: "Data type",
			field: "fieldType",
			width: 60,
			resizable: true,
			// editable: this.editAllowed, TODO: Allow edit on non standard SAFF fields only, once its implemented
			cellEditor: "agRichSelectCellEditor",
			valueGetter: (params) => {
				return this.getSaffColumnType(params.getValue("saffColumn"));
			},
			cellClassRules: {
				"border border-danger": (params: any) => {
					return !params.value || params.value === "";
				},
			},
		},
		{
			headerName: "Tab",
			field: "tab",
			width: 50,
			resizable: true,
			cellEditor: "agRichSelectCellEditor",
			editable: this.editAllowed,
			cellEditorParams: {
				values: [
					"PERSONAL",
					"ADDRESS",
					"EMPLOYER",
					"EMPLOYMENT",
					"FUND",
					"DB_REGISTRATION",
					"ADDITIONAL",
				],
			},
			valueSetter: (params) => {
				if (params.newValue) {
					params.data.tab = params.newValue;
					//set order to 0, as this will change the context of display order.
					params.data.displayOrder = 0;
					return true;
				}
				return false;
			},
			onCellValueChanged: (params: any) => {
				this.updateRowStatus(params);
			},
			cellClassRules: {
				"border border-danger": (params: any) => {
					return !params.value || params.value === "";
				},
			},
		},
		{
			headerName: "Status",
			field: "status",
			width: 100,
			resizable: true,
			hide: true,
		},
		{
			headerName: "Order",
			field: "displayOrder",
			width: 20,
			resizable: true,
			editable: this.editAllowed,
			hide: false,
			cellClassRules: {
				"border border-danger": (params: any) => {
					return (
						!params.value ||
						params.value === "" ||
						!this.matchNumber.test(params.value)
					);
				},
			},
			onCellValueChanged: (params: any) => {
				this.updateRowStatus(params);
				this.updateOrder(params);
				this.$refs.gridEl.api?.refreshCells();
			},
		},

		{
			headerName: "Actions",
			field: "__Actions",
			cellRenderer: this.actionsRender,
			pinned: "right",
			width: 20,
		},
	];
	get formIsValid(): boolean {
		return !!this.hasError;
	}

	private getSaffColumnType(saffColumnName: string) {
		return (
			this.saffColumnOptions.find((f) => f.name === saffColumnName)
				?.type ?? ""
		);
	}

	actionsRender(params: ICellRendererParamsTyped<Fund>): HTMLElement {
		const edit = this.editAllowed;
		const vm = new Vue({
			el: document.createElement("div"),

			render: (createElement) => {
				return createElement(GridActionsRenderer, {
					props: {
						rowIndex: params.rowIndex,
						row: params.data,
						isDelete: edit,
						isEdit: false,
					},
					on: {
						clickDelete: this.onDelete,
					},
				});
			},
		});
		this.gridVMList.push(vm);
		return vm.$el as HTMLElement;
	}

	emitUpdate(field: string, value: string) {
		if (field === "usi" && this.fundFormat) {
			this.fundFormat.usi = value;
		}
		this.$emit("change", { field: field, value: value });
	}
	/* Primary Functions */

	onDelete({ row }: { row: Fund }) {
		this.deleteConfirmation = true;
		this.rowForDeletion = row;
	}

	private onGridReady() {
		this.gridReady = true;
	}

	private onAdd() {
		this.funds.push({
			id: this.addId.toString(),
			tab: "",
			fundFormatId: Number(this.fundFormatId),
			saffColumn: "",
			fieldLabel: "",
			fieldType: "",
			displayOrder: 0,
			status: Status.NEW,
		});
		this.addId = this.addId - 1;
		if (!this.$refs.gridEl) {
			return;
		}
		this.$refs.gridEl.setRowData(this.funds);

		this.$refs.gridEl.paginationGoToPage(
			Math.ceil(this.funds.length / this.$refs.gridEl.paginationPageSize)
		);
	}

	private async deleteFund() {
		if (!this.rowForDeletion) return;
		if (this.rowForDeletion?.status !== Status.NEW) {
			const id = Number(this.rowForDeletion.id);
			await axios
				.delete(mappingsByFundFormatId(id), {
					params: {
						fundFormatId: Number(this.fundFormatId),
					},
				})
				.then(() => {
					this.deleteRow();
				})
				.catch((error) => {
					toastErrorMessage(parseErrorMessage(error));
				});
		} else {
			//no need to call api. (setting 'success' to false in catch doesn't work)
			this.deleteRow();
		}

		this.deleteConfirmation = false;
	}

	private deleteRow() {
		this.funds = this.funds.filter(
			(row) => row.id !== this.rowForDeletion?.id
		);
		if (!this.$refs.gridEl) {
			return;
		}
		this.$refs.gridEl.setRowData(this.funds);
		toastSuccessMessage("Mapping deleted successfully");
	}

	mounted() {
		this.add = this.fundFormatId === "new";
		if (this.add) {
			this.fundFormat = {
				id: 0,
				fundFormatName: "",
				usi: "",
			};
		} else if (this.fundFormatId) {
			axios
				.get<FundFormatAndMappingResponse>(
					fundFormatAndMappingsById(Number(this.fundFormatId))
				)
				.then((resp) => {
					this.funds = resp.data.mappings;
					this.fundFormat = resp.data.fundFormat;
					this.fixOrder();
					for (const row of this.funds) {
						row.status = Status.UNCHANGED;
					}
					if (this.funds.length === 0) this.onAdd();
				})
				.catch((error) => {
					toastErrorMessage(parseErrorMessage(error));
				});
			this.showGrid = true;
		}

		axios
			.get<[]>(saffColumnOptions())
			.then((resp) => {
				this.saffColumnOptions = resp.data;
			})
			.catch((error) => {
				toastErrorMessage(parseErrorMessage(error));
			});

		axios
			.get<ApraResponse[]>(getAllApraFunds())
			.then((resp: any) => {
				for (const a of resp.data) {
					if (
						a.fundUsi === null ||
						a.fundUsi === undefined ||
						a.fundUsi === ""
					) {
						continue;
					}
					a.value = a.fundUsi;
					a.label = `${a.fundName} - ${a.fundUsi}`;

					this.apraFunds.push(a);
				}
			})
			.catch((error) => {
				toastErrorMessage(parseErrorMessage(error));
			});
	}

	onSave() {
		this.$refs.gridEl.api?.stopEditing();
		this.$refs.gridEl.api?.deselectAll();
		this.$refs.gridEl.$nextTick().then(() => {
			const SaveObjects = this.buildSaveBody();
			if (this.add) {
				if (SaveObjects.length === 0) {
					toastInfoMessage("No changes to save");
					return;
				}
				axios
					.post<FundFormatAndMappingResponse>(
						createFundFormatAndMappings(),
						{
							fundFormat: this.fundFormat,
							saveObjects: SaveObjects,
						},
						{
							headers: {
								"Content-Type": "application/json",
							},
						}
					)
					.then((resp) => {
						if (resp.data === null) {
							toastErrorMessage("save failed");
							return;
						}
						this.fundFormat = resp.data.fundFormat;
						this.funds = resp.data.mappings;
						this.add = false;
						toastSuccessMessage("Mapping saved successfully");
						this.fixOrder();
						this.$router.push({
							name: "Configure fund mapping details",
							params: {
								fundFormatId: String(this.fundFormat.id),
							},
						});
					})
					.catch((error) => {
						toastErrorMessage(parseErrorMessage(error));
					});
			} else {
				axios
					.put<FundFormatAndMappingResponse>(
						fundFormatAndMappingsById(Number(this.fundFormatId)),
						{
							fundFormat: this.fundFormat,
							saveObjects: SaveObjects,
						},
						{
							headers: {
								"Content-Type": "application/json",
							},
						}
					)
					.then((resp) => {
						if (resp.data === null) {
							toastErrorMessage("save failed");
							return;
						}
						this.fundFormat = resp.data.fundFormat;
						this.funds = resp.data.mappings;
						this.add = false;
						toastSuccessMessage("Mapping saved successfully");
						this.fixOrder();
					})
					.catch((error) => {
						toastErrorMessage(parseErrorMessage(error));
					});
			}
		});
	}

	/* Secondary/Helper Functions.*/

	matchUSI(data: any, fund: Fund) {
		if (data.id !== "0") return data.id === fund.id;
		return data.saffColumn === fund.saffColumn && data.tab === fund.tab;
	}

	fixOrder() {
		let order = 0;
		let tab = "";
		//in the event a row is deleted while new rows have been added but not updated,
		//we need to skip over the new rows and only update the rows that have an order set.
		this.funds.forEach((fund) => {
			if (fund.tab !== tab && fund.displayOrder !== 0) {
				order = 0;
				tab = fund.tab;
			}
			order++;
			if (fund.displayOrder !== order) {
				fund.displayOrder = order;
				fund.status = Status.UPDATED;
			}
		});
	}

	removeValFromOrder(tab: string, order: number) {
		this.funds.forEach((fund) => {
			if (fund.tab === tab && fund.displayOrder > order) {
				fund.displayOrder--;
			}
		});
	}

	updateOrder(params: any) {
		if (params.data.displayOrder === 0) {
			this.removeValFromOrder(params.data.tab, params.data.displayOrder);
			return; //don't do anything if order hasn't been set.
		}
		let changed = false;
		let max = 0;
		for (const fund of this.funds) {
			if (
				!this.matchUSI(params.data, fund) &&
				fund.tab === params.data.tab &&
				fund.displayOrder !== 0
			) {
				//without typecasting (or even typecasting in the if statement) this doesn't work.
				const newVal = Number(params.data.displayOrder);
				const oldVal =
					params.oldValue === 0
						? Number.MAX_VALUE
						: Number(params.oldValue);
				const usiVal = Number(fund.displayOrder);
				if (newVal === oldVal) return; //no change needed
				if (usiVal >= newVal && usiVal <= oldVal) {
					//Matching USi and Saf, order is larger than the inserted row.
					fund.displayOrder = Number(fund.displayOrder) + Number(1);
					changed = true;
					if (fund.status !== Status.NEW)
						fund.status = Status.UPDATED;
				} else if (usiVal <= newVal && usiVal > oldVal) {
					//Matching USi and Saf, order is smaller than the inserted row, but larger than it's old value.
					fund.displayOrder = Number(fund.displayOrder) - Number(1);
					changed = true;
					if (fund.status !== Status.NEW)
						fund.status = Status.UPDATED;
				} else {
					//this is a match for saff and usi, but the given order is smaller than the current row,
					//so we update the max to be the current row order
					//we use this to ensure that we don't add an order greater than the number of rows.
					if (fund.displayOrder > max) {
						max = fund.displayOrder.valueOf();
					}
				}
			}
		}
		if (!changed) {
			//if no change, then the current row is the last row. set it appropriately.
			//I don't know why but it was treating them as strings without typecasting, even though typeof showed number.
			max = Number(max) + Number(1);
			params.data.displayOrder = max;
		}
	}

	private buildSaveBody(): SaveObject[] {
		const body: SaveObject[] = [];
		for (const row of this.funds) {
			if (row.status === Status.UNCHANGED) {
				continue;
			}
			const splitSaffColumnId = row.saffColumn.split("-")[0].trim();
			const id = parseInt(row.id);
			const saveObj: SaveObject = {
				id: id < 0 ? 0 : id,
				tab: row.tab,
				saffColumn: splitSaffColumnId,
				fundFormatId: Number(this.fundFormatId),
				order: row.displayOrder,
			};
			body.push(saveObj);
		}
		return body;
	}

	private getSaffColumnOptions() {
		return {
			values: this.saffColumnOptions.map((col) => col.name),
			cellRenderer: (params: { value: any }) => {
				return `<div style="display:inline-block;width:600px;">${params.value}</div>`;
			},
		};
	}
	/* Error Checking and Validation  */

	get saffColumnHasError() {
		return this.funds.find(
			(row) => row.saffColumn === "" || row.saffColumn === "-"
		);
	}

	get tabHasError() {
		const error = this.funds.map((row) => {
			if (row.tab === undefined || row.tab === "") {
				return true;
			}
		});
		return error.includes(true);
	}

	get orderHasError() {
		const error = this.funds.map((row) => {
			if (row.displayOrder === undefined || row.displayOrder === 0) {
				return true;
			}
		});
		return error.includes(true);
	}

	get hasError() {
		return this.funds.find(
			(row) =>
				row.displayOrder === undefined ||
				row.displayOrder === 0 ||
				row.tab === undefined ||
				row.tab === "" ||
				row.saffColumn === undefined ||
				row.saffColumn === "" ||
				row.saffColumn === "-" ||
				this.duplicateSaffColumns
		);
	}

	get duplicateSaffColumns() {
		const saffColumns = this.funds.map((row) => {
			return row.saffColumn;
		});
		const uniqueSaffColumns = [...new Set(saffColumns)];
		return uniqueSaffColumns.length !== saffColumns.length;
	}

	private isSaffDuplicate(params: any): boolean {
		return !!this.funds.find(
			(row) =>
				row.id !== params.data.id &&
				row.saffColumn === params.data.saffColumn
		);
	}

	clearErrorMessage() {
		this.errorMessage = null;
	}

	onBack() {
		this.$router.push({
			name: this.editAllowed
				? "View USI mappings"
				: "Configure fund mapping details",
		});
	}
}
