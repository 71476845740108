
















































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import { TSelectLevel } from "@/components/employerSelectorTypes";
import EmployerSelector from "@/components/EmployerSelector.vue";
import { createNamespacedHelpers } from "vuex";
import { isOnlyOneChildlessEmployerSelected } from "@/utils/EmployerSelectorUtils";
import { NO_RC_ERROR_MESSAGE } from "@/constants/constants";
import axios, { axiosStatic } from "@/utils/ApiUtils";
import {
	accountBalanceDetailURL,
	accountBalanceHeaderURL,
} from "@/constants/apiconstants";
import { toastErrorMessage } from "@/plugins/toasts";
import { parseErrorMessage } from "@/utils/ErrorUtils";
import { FilterModel, PagedResult, YesNoAllType } from "@/grid/gridTypes";
import {
	ColDef,
	ColGroupDef,
	IServerSideDatasourceTyped,
	IServerSideGetRowsParamsTyped,
	ICellRendererParamsTyped,
} from "ag-grid-community";
import { EmployerHierarchy } from "@/store/modules/persistent/persistentTypes";
import Grid from "@/grid/Grid.vue";
import GridFilter from "@/components/GridFilter.vue";
import TextField from "@/form/TextField.vue";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { GridAggFunction } from "@/grid/gridConstants";
import SelectField from "@/form/SelectField.vue";
import { yesNoAllType } from "@/constants/pageConstants";
import GridActionsRenderer from "@/grid/GridActionsRenderer.vue";
import { commitToModule, registerModule } from "@/store/modules/filters";

const { mapState, mapMutations } = createNamespacedHelpers("persistent");

// Corresponds to AccountBalanceResource.AccountBalanceHeader
interface AccountBalanceHeader {
	entityName: string;
	outstandingBalance: string;
}

// Corresponds to AccountBalanceResource.AccountBalanceDetail
interface AccountBalanceDetail {
	employer: string;
	paymentProfile: string;
	outstandingBalance: number;
	reportingCentre: string;
	unfundedBatches: number;
	amountOwing: number;
	member: string;
	rcId: number;
}

@Component({
	components: {
		Layout,
		EmployerSelector,
		Grid,
		GridFilter,
		TextField,
		SelectField,
	},
	computed: mapState(["selectedEntities", "employerHierarchy"]),
	methods: {
		...mapMutations(["setSelectedEntities"]),
	},
})
export default class AccountBalancePage
	extends Vue
	implements IServerSideDatasourceTyped
{
	private pageContext: TSelectLevel = "ALL";
	private errorMessage: string | null = null;
	private gridReady = false;
	selectedEntities!: string[];
	employerHierarchy!: EmployerHierarchy[];
	private header: AccountBalanceHeader | null = null;
	readonly yesNoOption = yesNoAllType;
	private vuexStore = "accountBalancePage";

	public $refs!: {
		gridEl: Grid;
	};

	private gridVMList: Vue[] = [];

	private autoGroupColumnDef = {
		cellRendererParams: {
			suppressCount: true,
		},
	};

	/**
	 * Type the mapped persistent.setSelectedEntities mutation.
	 */
	setSelectedEntities!: (selectedEntities: string[]) => void;

	// Dirty workaround for aggregated SUM_OF_CURRENCY to be displayed only at parent nodes (will not work with export)
	readonly outstandingBalanceHeaderRenderer = (
		params: ICellRendererParamsTyped<AccountBalanceDetail>
	) => {
		return params.value.startsWith("$") ? params.value : "";
	};

	private readonly columnDefs: (ColGroupDef | ColDef)[] = [
		{
			headerName: "Employer",
			field: "employer",
			width: 200,
			resizable: true,
			rowGroup: true,
			hide: true,
		},
		{
			headerName: "Payment profile",
			field: "paymentProfile",
			width: 200,
			resizable: true,
			rowGroup: true,
			hide: true,
		},
		{
			headerName: "Available",
			field: "outstandingBalance",
			width: 100,
			resizable: true,
			aggFunc: GridAggFunction.SUM_OF_CURRENCY,
			cellRenderer: this.outstandingBalanceHeaderRenderer,
		},
		{
			headerName: "Reporting centre",
			field: "reportingCentre",
			width: 200,
			resizable: true,
		},
		{
			headerName: "Unfunded batches",
			field: "unfundedBatches",
			width: 100,
			resizable: true,
			aggFunc: GridAggFunction.SUM_OF_CURRENCY,
		},
		{
			headerName: "Amount Owing",
			field: "amountOwing",
			width: 100,
			resizable: true,
			aggFunc: GridAggFunction.SUM_OF_CURRENCY,
		},
		{
			headerName: "Transactions",
			field: "__Actions",
			cellRenderer: this.actionsRender,
			width: 75,
		},
	];

	actionsRender(
		params: ICellRendererParamsTyped<AccountBalanceDetail>
	): HTMLElement {
		const view = params.node.field === "paymentProfile";
		const vm = new Vue({
			el: document.createElement("div"),

			render: (createElement) => {
				return createElement(GridActionsRenderer, {
					props: {
						rowIndex: params.rowIndex,
						row: view ? params.node.allLeafChildren[0].data : null,
						isEdit: false,
						// enable view only at Payment profile level
						isView: view,
					},
					on: {
						clickView: this.viewTransactionDetails,
					},
				});
			},
		});
		this.gridVMList.push(vm);
		return vm.$el as HTMLElement;
	}

	viewTransactionDetails({ row }: { row: AccountBalanceDetail }) {
		this.$router.push({
			name: "Transaction details",
			params: {
				member: row.member,
			},
		});
	}

	private filterModel: FilterModel = {
		withOutstandingBalance: {
			value: YesNoAllType.ALL,
			column: "withOutstandingBalance",
		},
		withUnfundedBatches: {
			value: YesNoAllType.ALL,
			column: "withUnfundedBatches",
		},
		activePaymentProfile: {
			value: YesNoAllType.YES,
			column: "activePaymentProfile",
		},
		amountOwingOnly: {
			value: YesNoAllType.ALL,
			column: "amountOwingOnly",
		},
		rowsPerPage: {
			value: "50",
			column: "rowsPerPage",
		},
	};
	private modules = [RowGroupingModule];

	private onGridReady() {
		this.gridReady = true;
	}

	private onApplyFilter() {
		this.$refs.gridEl.reload();
	}

	onResetFilter() {
		this.filterModel.withOutstandingBalance.value = YesNoAllType.ALL;
		this.filterModel.withUnfundedBatches.value = YesNoAllType.ALL;
		this.filterModel.amountOwingOnly.value = YesNoAllType.ALL;
		this.filterModel.activePaymentProfile.value = YesNoAllType.YES;
		commitToModule(this.$store, this.vuexStore, this.filterModel);
		this.onApplyFilter();
	}

	created() {
		const selectEntityParam = this.$route.query.selectEntity as string;
		if (selectEntityParam && selectEntityParam !== "") {
			this.setSelectedEntities([selectEntityParam]);
		}

		this.onSelectedReportingCenterChanged();
		registerModule(this.$store, this.vuexStore, this.filterModel);
		this.filterModel = this.$store.getters[`${this.vuexStore}/filters`];
	}

	get selectedReportingCenter() {
		return this.$store.state.persistent.selectedEntities;
	}

	@Watch("selectedReportingCenter")
	onSelectedReportingCenterChanged() {
		if (this.selectedReportingCenter.length !== 1) {
			this.header = null;
		} else {
			this.getHeader();
			this.reloadGrid();
		}
	}

	private getHeader() {
		if (this.selectedReportingCenter.length !== 1) {
			return;
		}

		axios
			.get<AccountBalanceHeader>(accountBalanceHeaderURL(), {
				params: {
					entities: this.selectedReportingCenter,
				},
			})
			.then((resp) => {
				this.header = resp.data;
			});
	}

	private reloadGrid(): void {
		if (this.selectedReportingCenter.length !== 1) {
			return;
		}

		this.gridReady = false;
		if (!this.$refs.gridEl) {
			return;
		}
		this.$refs.gridEl.reload();
		this.gridReady = true;
	}

	getRows(params: IServerSideGetRowsParamsTyped<AccountBalanceDetail>): void {
		if (this.selectedReportingCenter.length !== 1) {
			return;
		}

		if (
			isOnlyOneChildlessEmployerSelected(
				this.selectedEntities,
				this.employerHierarchy
			)
		) {
			this.errorMessage = NO_RC_ERROR_MESSAGE;
			params.successCallback([]);
			return;
		}

		params.request.filterModel = Object.keys(this.filterModel).map(
			(key) => {
				return this.filterModel[key];
			}
		);

		axios
			.get<PagedResult<AccountBalanceDetail>>(accountBalanceDetailURL(), {
				params: {
					// Pass in <Grid> parameters
					grid: params.request,
					entities: this.$store.state.persistent.selectedEntities,
				},
				cancelToken: params.cancelToken,
			})
			.then((response) => {
				params.successCallback(response.data);
			})
			.catch((error) => {
				if (axiosStatic.isCancel(error)) {
					return;
				}
				toastErrorMessage(parseErrorMessage(error));
			});
	}

	clearErrorMessage() {
		this.errorMessage = null;
	}
}
