var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('ProgressArrow',{attrs:{"steps":_vm.steps}}),_c('PageHeader',{attrs:{"title":_vm.title,"icon":['fal', 'list-alt']}}),_c('br'),(_vm.errors && _vm.errors.length > 0)?_c('ErrorList',{attrs:{"errors":_vm.errors}}):_vm._e(),(!_vm.errors || _vm.errors.length === 0)?_c('div',[_c('SummaryList',[_c('SummaryListItem',{attrs:{"name":"Contribution batch ID","value":_vm.parentBatchId}}),_c('SummaryListItem',{attrs:{"name":"Filename","value":_vm.filenameOrBatchname}}),_c('SummaryListItem',{attrs:{"name":"Number of reporting centres","value":_vm.reportingCentreCount}}),_c('SummaryListItem',{attrs:{"name":"Number of employees","value":_vm.employeeCount}}),_c('SummaryListItem',{attrs:{"name":"Number of funds","value":_vm.fundCount}}),_c('SummaryListItem',{attrs:{"name":"Contribution batch status","value":_vm.batchStatus}}),_c('SummaryListItem',{attrs:{"name":"Number of errors","value":_vm.errorCount}}),_c('SummaryListItem',{attrs:{"name":"Number of warnings","value":_vm.warningCount}}),_c('SummaryListItem',{attrs:{"name":"Total contribution amount","value":_vm.totalDollarValue}})],1),_c('LeftRightFooter',{attrs:{"has-padding-top":""},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('Button',{attrs:{"data-cy":"dataInputRefreshButton","disabled":!_vm.canRefresh ||
						_vm.intervalData.remaining === 0 ||
						_vm.intervalData.isRefreshing},on:{"click":_vm.onClickRefresh}},[_c('div',{attrs:{"id":"interval-button-text"}},[_vm._v(" Refresh "+_vm._s(_vm.refreshCount)+" "),(
								!_vm.refreshCount &&
								(!_vm.canRefresh ||
									_vm.intervalData.remaining === 0 ||
									_vm.intervalData.isRefreshing)
							)?_c('font-awesome-icon',{attrs:{"icon":['fal', 'spinner'],"spin":""}}):_vm._e()],1)]),_c('Button',{attrs:{"disabled":!_vm.canClickNext ||
						!(
							_vm.summary &&
							(_vm.summary.errors > 0 || _vm.summary.warnings > 0)
						)},on:{"click":_vm.onClickDownloadErrorReport}},[_vm._v(" Download error report ")])]},proxy:true}],null,false,3004547095)},[[_c('Button',{attrs:{"disabled":!_vm.canClickCancelBatch},on:{"click":_vm.onClickCancelBatch}},[_vm._v(" Cancel contribution batch ")]),_c('Button',{attrs:{"variant":"primary","disabled":!_vm.canClickNext,"data-cy":"dataInputNextButton"},on:{"click":_vm.onClickNext}},[_vm._v(" Next ")])]],2),_c('div',{staticClass:"help-text"},[_vm._v(" Please click the 'Refresh' button to view the progress of the file validation. The 'Next' button will be enabled when this process has been completed. ")]),(_vm.isCancelModalShown)?_c('ModalWithSaveAndCancel',{attrs:{"title":"Cancel Contribution Batch"},on:{"cancel":function($event){return _vm.closeCancelBatch()},"confirm":function($event){return _vm.triggerBatchCancel()}}},[_c('strong',[_vm._v("Cancelling the contribution batch will delete records of the contribution batch from the portal")])]):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }