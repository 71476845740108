/**
 * RoutePath is an enum of all the available routes.
 *
 * This allows us to rename paths to certain routes without breaking links
 * within components that link off to different routes.
 */
export const enum RoutePath {
	Dashboard = "/dashboard",
	Login = "/login",
	EmployeeList = "/employees",
	FundList = "/funds",
	BatchList = "/batches",
	PaymentList = "/payments",
	Maintenance = "/maintenance",
	Reports = "/reports",
	EmployeeReport = "/reports/Employee",
	FundReport = "/reports/Fund",
	RefundReport = "/reports/refund",
	DefinedBenefitReport = "/reports/defined-benefit",
	DefinedBenefitReportView = "/reports/defined-benefit/:empId/:repId",
	RefundReportV2 = "/reports/refund/v2",
	Help = "/help",
	HelpDocumentation = "/help/documentation",
	HelpVideos = "/help/videos",
	CreateBatch = "/create-batch",
	Contribution = "/contribution/:id",
	FileLoadSummary = "/contribution/:id/file-load-summary",
	DataInput = "/contribution/:id/data-input",
	FileErrorPage = "/contribution/:id/file-error",
	ContributionValidateContents = "/contribution/:id/validate-contents",
	FinalBatchReview = "/contribution/:id/final-batch-review",
	PaymentDetails = "/contribution/:id/payment-details",
	BatchDetails = "/batch/:id",
	AddFund = "/add-fund",
	EditFund = "/edit-fund/:payeeCode/:fundType/:reportingCentreId",
	ViewFund = "/view-fund/:payeeCode/:fundType/:reportingCentreId",
	Forbidden = "/forbidden",
	NotFound = "/404",
	NewsMaintenance = "/maintenance/news",
	UserMaintenance = "/maintenance/user",
	RegistrationOfInterest = "/registration-of-interest",
	CompanyMaintenance = "/maintenance/company-maintenance",
	AddEmployee = "/employees/:mode/:reportingCentreId",
	AddDbEmployee = "/employees/defined-benefit/:mode/:reportingCentreId",
	EditEmployee = "/employees/:mode/:id/:reportingCentreId",
	AddEmployer = "/maintenance/company-maintenance/add-employer/:parentOrganisationId",
	AddReportingCentre = "/maintenance/company-maintenance/add-reporting-centre/:employerId/:rcStrategy/",
	PortalUser = "/maintenance/user",
	AddPortalUser = "/maintenance/:mode/user",
	EditPortalUser = "/maintenance/:mode/user/:userId",
	ViewPortalUser = "/maintenance/:mode/user/:userId",
	EmployerRegistrationMaintenance = "/maintenance/employer-registration",
	ViewEmployerRegistration = "/maintenance/employer-registration/:employerRegistrationId",
	Profile = "/profile",
	Alerts = "/alerts",
	EmployerFileFormat = "/admin-ops/file-format",
	AccountBalance = "/account-balance",
	AccountBalanceTransactions = "/account-balance/:member/transactions",
	EmployerFundFormat = "/admin-ops/fund",
	ViewFileMapping = "/admin-ops/view-file-mapping",
	MaintainFileMapping = "/admin-ops/maintain-file-mapping",
	ViewFileMappingDetails = "/admin-ops/view-file-mapping/:fileFormatId/details",
	MaintainFileMappingDetails = "/admin-ops/maintain-file-mapping/:fileFormatId/details",
	FundMapping = "/admin-ops/fund-mapping",
	UsiMappingDetails = "/admin-ops/usi-mapping/:fundFormatId/details",
	Insight = "/insight",
	BulkFile = "/bulk-file",
	ReleaseNotes = "/release-notes",
	FundMessage = "/fund-message/:createView/:id",
	AdminOps = "/admin-ops",
}
