

















































import Vue from "vue";
import { Component } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import Grid from "@/grid/Grid.vue";
import Button from "@/form/Button.vue";
import {
	ColDef,
	ColGroupDef,
	ICellRendererParamsTyped,
	IServerSideDatasourceTyped,
	IServerSideGetRowsParamsTyped,
} from "ag-grid-community";
import GridActionsRenderer from "@/grid/GridActionsRenderer.vue";
import { hasPermission } from "@/utils/PermissionUtils";
import { FundFormat } from "./FundFormatAndMapping";
import ModalOrPopup from "@/components/ModalOrPopup.vue";
import axios, { axiosStatic } from "@/utils/ApiUtils";
import { PagedResult } from "@/grid/gridTypes";
import {
	fundFormatAndMappingsById,
	fundFormatsAll,
} from "@/constants/apiconstants";
import { toastErrorMessage, toastSuccessMessage } from "@/plugins/toasts";
import { parseErrorMessage } from "@/utils/ErrorUtils";

@Component({
	components: { Layout, Grid, Button, ModalOrPopup },
})
export default class UsiMappingPage
	extends Vue
	implements IServerSideDatasourceTyped
{
	private readonly fundMapping: FundFormat[] = [];

	public $refs!: {
		gridEl: Grid;
	};

	private gridVMList: Vue[] = [];
	private gridReady = false;
	private deleteConfirmation = false;
	private rowForDeletion: FundFormat | null = null;

	private readonly editAllowed = hasPermission("VIEW_FILE_MAPPING");

	private readonly columnDefs: (ColGroupDef | ColDef)[] = [
		{
			headerName: "ID",
			field: "id",
			width: 60,
			resizable: true,
			hide: true,
		},
		{
			headerName: "Fund mapping name",
			field: "fundFormatName",
			width: 250,
			resizable: true,
		},
		{
			headerName: "Fund",
			field: "usi",
			width: 400,
			resizable: true,
		},
		{
			headerName: "Actions",
			field: "__Actions",
			cellRenderer: this.actionsRender,
			width: 60,
		},
	];

	actionsRender(params: ICellRendererParamsTyped<FundFormat>): HTMLElement {
		const edit = this.editAllowed;
		const vm = new Vue({
			el: document.createElement("div"),

			render: (createElement) => {
				return createElement(GridActionsRenderer, {
					props: {
						rowIndex: params.rowIndex,
						row: params.data,
						isEdit: edit,
						isView: !edit,
						isDelete: edit,
					},
					on: {
						clickEdit: this.viewDetails,
						clickView: this.viewDetails,
						clickDelete: this.onDelete,
					},
				});
			},
		});
		this.gridVMList.push(vm);
		return vm.$el as HTMLElement;
	}

	viewDetails({ row }: { row: FundFormat }) {
		this.$router.push({
			name: this.editAllowed
				? "Configure fund mapping details"
				: "View USI mappings",
			params: {
				fundFormatId: String(row.id),
			},
		});
	}

	onDelete({ row }: { row: FundFormat }) {
		this.deleteConfirmation = true;
		this.rowForDeletion = row;
	}

	private onGridReady() {
		this.gridReady = true;
	}

	private onAdd() {
		this.$router.push({
			name: "Configure fund mapping details",
			params: {
				fundFormatId: String("new"),
			},
		});
	}

	private deleteFileFormat() {
		if (this.rowForDeletion) {
			this.deleteConfirmation = false;
			this.deleteConfirmation = false;
			axios
				.delete(fundFormatAndMappingsById(this.rowForDeletion?.id))
				.then((resp) => {
					this.$refs.gridEl.reload();
					toastSuccessMessage(
						"Fund field format deleted successfully"
					);
				})
				.catch((error) => {
					toastErrorMessage(parseErrorMessage(error));
				});
		}
	}

	getRows(params: IServerSideGetRowsParamsTyped<FundFormat>): void {
		axios
			.get<PagedResult<FundFormat>>(fundFormatsAll(), {
				params: {
					// Pass in <Grid> parameters
					grid: params.request,
				},
				cancelToken: params.cancelToken,
			})
			.then((response) => {
				params.successCallback(response.data);
			})
			.catch((error) => {
				if (axiosStatic.isCancel(error)) {
					return;
				}
				toastErrorMessage(parseErrorMessage(error));
			});
	}
}
