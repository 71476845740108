


















































































































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import EmployerSelector from "@/components/EmployerSelector.vue";
import Grid from "@/grid/Grid.vue";
import { FilterModel, PagedResult, TranslationMapping } from "@/grid/gridTypes";
import {
	ColDef,
	ColGroupDef,
	ColumnApi,
	GridApi,
	ICellRendererParamsTyped,
	IServerSideGetRowsParamsTyped,
	ValueFormatterParams,
} from "ag-grid-community";
import axios, { axiosStatic } from "@/utils/ApiUtils";
import { batchListURL } from "@/constants/apiconstants";
import { parseErrorMessage } from "@/utils/ErrorUtils";
import Button from "@/form/Button.vue";
import {
	acurityColumnDateFormatter,
	columnDateTimeFormatter,
	getDateRangeForPastNMonths,
	getFormattedCurrency,
} from "@/utils/CommonUtils";
import { Batch } from "@/pages/batchType";
import { batchStatusMapping } from "@/utils/BatchUtils";
import { TSelectLevel } from "@/components/employerSelectorTypes";
import { toastErrorMessage } from "@/plugins/toasts";
import Accordion from "@/components/Accordion.vue";
import GridErrorWarningRenderer from "@/grid/GridErrorWarningRenderer.vue";
import { isOnlyOneChildlessEmployerSelected } from "@/utils/EmployerSelectorUtils";
import { NO_RC_ERROR_MESSAGE } from "@/constants/constants";
import { EmployerHierarchy } from "@/store/modules/persistent/persistentTypes";
import { createNamespacedHelpers } from "vuex";
import GridFilter from "@/components/GridFilter.vue";
import DatepickerField from "@/form/DatepickerField.vue";
import TextField from "@/form/TextField.vue";
import CheckBox from "@/form/CheckBox.vue";
import MultiSelect from "@/form/MultiSelect.vue";
import { downloadFile } from "@/utils/DownloadUtils";
import moment from "moment";
import { commitToModule, registerModule } from "@/store/modules/filters";
import { batchType } from "@/constants/pageConstants";
import SelectField from "@/form/SelectField.vue";

const { mapState } = createNamespacedHelpers("persistent");
@Component({
	components: {
		CheckBox,
		DatepickerField,
		GridFilter,
		EmployerSelector,
		Grid,
		Layout,
		Button,
		Accordion,
		TextField,
		MultiSelect,
		SelectField,
	},
	computed: mapState([
		"selectedEntities",
		"employerHierarchy",
		"definedBenefitEntities",
	]),
})
export default class BatchListPage extends Vue {
	private vuexStore = "batchesPage";
	/**
	 * Type the mapped persistent.selectedEntities getter.
	 * It is a computed property. Do not mutate it.
	 */
	selectedEntities!: string[];

	/**
	 * Type the mapped persistent.employerHierarchy getter.
	 * It is a computed property. Do not mutate it.
	 */
	employerHierarchy!: EmployerHierarchy[];
	definedBenefitEntities!: string[];
	private pageContext: TSelectLevel = "ALL";
	private multiSelect = true;
	private batchStatusOptions: TranslationMapping[] =
		batchStatusMapping().filter(
			(status: TranslationMapping) =>
				!status.translatedValue.endsWith("ing")
		);
	private selectedBatchStatus: TranslationMapping[] = [];
	private errorMessage: string | null = null;
	public $refs!: {
		gridEl: Grid;
	};
	private filterModel: FilterModel = {
		batchId: {
			value: "",
			column: "batchId",
		},
		createdDate: {
			value: this.getDefaultCreatedDate(),
			column: "createdDate",
		},
		lastUpdatedDate: {
			value: "",
			column: "lastUpdatedDate",
		},
		hasSSResponse: {
			value: false,
			column: "hasSSResponse",
		},
		status: {
			value: "",
			column: "status",
		},
		showDeletedBatch: {
			value: false,
			column: "showDeletedBatch",
		},
		awaitingPayment: {
			value: false,
			column: "awaitingPayment",
		},
		payPeriodDate: {
			value: "",
			column: "payPeriodDate",
		},
		batchType: {
			value: "",
			column: "batchType",
		},
	};

	readonly batchTypeOptions = batchType;

	created() {
		registerModule(this.$store, this.vuexStore, this.filterModel);
		this.filterModel = this.$store.getters[`${this.vuexStore}/filters`];
		const foundStatus = batchStatusMapping().find(
			(status) =>
				status.value ===
				this.$store.getters[`${this.vuexStore}/filters`].status.value
		);
		this.selectedBatchStatus = foundStatus ? [foundStatus] : [];
	}

	private getDefaultCreatedDate(): string {
		return getDateRangeForPastNMonths(6);
	}

	warningErrorRender(params: ICellRendererParamsTyped<Batch>): HTMLElement {
		const vm = new Vue({
			el: document.createElement("div"),
			render: (createElement) => {
				const awaitingAuthorisation = createElement(
					GridErrorWarningRenderer,
					{
						props: {
							rowIndex: params.rowIndex,
							row: params.data,
							withWarning: params.data.withAwaitAuth,
							customWarningIcon: "pause-circle",
							withInfo: false,
							warningTitle: "Awaiting authorisation",
						},
						class: "grid-notification-column__item-left",
					}
				);

				const notPaidWarningNodeDescription = createElement(
					GridErrorWarningRenderer,
					{
						props: {
							rowIndex: params.rowIndex,
							row: params.data,
							withWarning: params.data.withNotPaidWarning,
							customWarningIcon: "sack-dollar",
							withInfo: false,
							warningTitle: "Payment not received",
						},
						class: "grid-notification-column__item-left",
					}
				);

				const superStreamErrorWarningNodeDescription = createElement(
					GridErrorWarningRenderer,
					{
						props: {
							rowIndex: params.rowIndex,
							row: params.data,
							withWarning: params.data.withMror,
							withError: params.data.withCter,
							customWarningIcon: "repeat-alt",
							customErrorIcon: "repeat-alt",
							warningTitle: "MROR response received",
							errorTitle: "CTER response received",
							withInfo: false,
						},
						class: "grid-notification-column__item-left",
					}
				);

				return createElement(
					"div",
					{
						class: "grid-notification-column__inner",
					},
					[
						awaitingAuthorisation, // this is never shown at the same time as the others (batches cannot go back to awaiting auth)
						notPaidWarningNodeDescription,
						superStreamErrorWarningNodeDescription,
					]
				);
			},
		});
		return vm.$el as HTMLElement;
	}

	private readonly columnDefs: (ColGroupDef | ColDef)[] = [
		{
			headerName: "",
			field: "notification",
			cellRenderer: this.warningErrorRender,
			resizable: true,
			pinned: "left",
			cellClass: "grid-notification-column",
			suppressSizeToFit: true,
			minWidth: 45,
		},
		{
			headerName: "Contribution batch ID",
			field: "id",
			resizable: true,
		},
		{
			headerName: "File name",
			field: "fileName",
			resizable: true,
		},
		{
			headerName: "Pay period start",
			field: "periodStartDate",
			resizable: true,
			valueFormatter: acurityColumnDateFormatter,
		},
		{
			headerName: "Pay period end",
			field: "periodEndDate",
			resizable: true,
			valueFormatter: acurityColumnDateFormatter,
		},
		{
			headerName: "Reporting centre",
			field: "reportingCentre",
			resizable: true,
		},
		{
			headerName: "Total amount",
			field: "totalAmount",
			resizable: true,
			valueFormatter: this.currencyFormatter,
		},
		{
			headerName: "Last updated",
			field: "statusDate",
			resizable: true,
			sortable: true,
			valueFormatter: columnDateTimeFormatter,
		},
		{
			headerName: "Current status",
			field: "status",
			resizable: true,
			cellRendererParams: {
				translationMappings: batchStatusMapping(),
			},
			cellRenderer: "valueTranslatedCellRenderer",
			valueFormatter: this.statusValueFormatter, // for export/clipboard
		},
	];

	private statusValueFormatter(params: ValueFormatterParams): string {
		const foundMapping = batchStatusMapping().find(
			(element: TranslationMapping) => params.value === element.value
		);
		return foundMapping ? foundMapping.translatedValue : "";
	}

	private rowSelection = "single";
	private isRefreshing = false;

	private triggerRefresh() {
		if (!this.$refs || !this.$refs.gridEl) {
			return;
		}
		this.isRefreshing = true;
		this.$refs.gridEl.reload();
	}

	private currencyFormatter(param: ValueFormatterParams): string {
		if (param.value === null) {
			return "N/A";
		} else {
			return getFormattedCurrency(param.value);
		}
	}

	get selectedReportingCenter() {
		return this.$store.state.persistent.selectedEntities;
	}

	private onRowClicked(batch: Batch) {
		this.$refs.gridEl.showLoadingOverlay();
		this.$router.push({
			name: "Batch Details",
			params: {
				id: batch.id,
			},
		});
	}

	@Watch("selectedReportingCenter")
	onSelectedReportingCenterChanged() {
		this.triggerRefresh();
	}

	getRows(params: IServerSideGetRowsParamsTyped<Batch>): void {
		if (
			isOnlyOneChildlessEmployerSelected(
				this.selectedEntities,
				this.employerHierarchy
			)
		) {
			this.errorMessage = NO_RC_ERROR_MESSAGE;
			params.successCallback([]);
			return;
		}
		const value =
			this.$store.state.persistent.selectedEntities == null
				? []
				: this.$store.state.persistent.selectedEntities;
		params.request.filterModel = Object.keys(this.filterModel).map(
			(key) => {
				return this.filterModel[key];
			}
		);
		this.filterModel.status.value = this.selectedBatchStatus
			.map((status) => status.value)
			.toString();
		axios
			.get<PagedResult<Batch>>(batchListURL(), {
				params: {
					grid: params.request,
					entities: value,
				},
				cancelToken: params.cancelToken,
			})
			.then((resp) => {
				const pagedResultData = resp.data;
				this.hideNotificationColumnIfNoWarning(
					params.api,
					params.columnApi,
					pagedResultData
				);

				params.successCallback(pagedResultData);
				this.isRefreshing = false;
			})
			.catch((e) => {
				params.failCallback();
				if (!axiosStatic.isCancel(e)) {
					toastErrorMessage(parseErrorMessage(e));
				}
			});
	}

	private static readonly ONE_NOIFICATION_COLUMN = 45;
	private static readonly TWO_NOIFICATION_COLUMNS = 90;

	/**
	 * The column can be hidden if no warning/error is not present.
	 */
	private hideNotificationColumnIfNoWarning(
		gridApi: GridApi,
		columnApi: ColumnApi,
		pagedResultData: PagedResult<Batch>
	) {
		const hasPaymentNotification = pagedResultData.elements.some(
			(batch) => batch.withNotPaidWarning
		);
		const hasSuperstreamNotification = pagedResultData.elements.some(
			(batch) => batch.withCter || batch.withMror
		);

		const columnDef = gridApi.getColumnDef("notification");
		if (!columnDef) {
			return;
		}

		columnApi.setColumnWidth(
			"notification",
			hasPaymentNotification && hasSuperstreamNotification
				? BatchListPage.TWO_NOIFICATION_COLUMNS
				: BatchListPage.ONE_NOIFICATION_COLUMN
		);

		columnApi.setColumnVisible(
			"notification",
			hasPaymentNotification || hasSuperstreamNotification
		);
		gridApi.sizeColumnsToFit();
	}

	private clearErrorMessage() {
		this.errorMessage = null;
	}
	constructor() {
		super();
	}
	private onResetFilter() {
		this.filterModel.batchId.value = "";
		this.filterModel.createdDate.value = this.getDefaultCreatedDate();
		this.filterModel.lastUpdatedDate.value = "";
		this.selectedBatchStatus = [];
		this.filterModel.status.value = "";
		this.filterModel.hasSSResponse.value = false;
		this.filterModel.showDeletedBatch.value = false;
		this.filterModel.awaitingPayment.value = false;
		this.filterModel.payPeriodDate.value = "";
		this.filterModel.batchType.value = "";
		this.onApplyFilter();
	}
	get disableStatusFilter() {
		return (
			this.filterModel.awaitingPayment.value ||
			this.filterModel.hasSSResponse.value
		);
	}

	private onApplyFilter() {
		const updatedFilterModel: FilterModel = {};
		Object.assign(updatedFilterModel, this.filterModel);
		updatedFilterModel.status.value = this.selectedBatchStatus
			.map((status) => status.value)
			.toString();
		commitToModule(this.$store, this.vuexStore, updatedFilterModel);
		this.$refs.gridEl.reload();
	}

	private getLabel(option: TranslationMapping) {
		return option.translatedValue;
	}

	exportCsv() {
		const entities = [...this.$store.state.persistent.selectedEntities];
		downloadFile(
			"/api/batch/export",
			`Batches_${moment().format("YYYY-MM-DD")}.csv`,
			{ entities }
		);
	}
}
