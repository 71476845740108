






































































































































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import ReportTable from "@/components/ReportTable.vue";
import Layout from "@/components/Layout.vue";
import PageHeader from "@/components/PageHeader.vue";
import Grid from "@/grid/Grid.vue";
import {
	ColDef,
	ColGroupDef,
	ICellRendererParamsTyped,
	IServerSideDatasourceTyped,
	IServerSideGetRowsParamsTyped,
	ValueFormatterParams,
} from "ag-grid-community";
import EmployerSelector from "@/components/EmployerSelector.vue";
import Button from "@/form/Button.vue";
import { FilterModel, PagedResult } from "@/grid/gridTypes";
import { commitToModule, registerModule } from "@/store/modules/filters";
import GridFilter from "@/components/GridFilter.vue";
import DatepickerField from "@/form/DatepickerField.vue";
import Modal from "@/components/Modal.vue";
import Form from "@/form/Form.vue";
import AutoField from "@/form/AutoField.vue";
import LeftRightFooter from "@/components/LeftRightFooter.vue";
import { toastErrorMessage, toastInfoMessage } from "@/plugins/toasts";
import { createNamespacedHelpers, mapMutations } from "vuex";
import axios, { axiosStatic } from "@/utils/ApiUtils";
import { definedBenefitReport } from "@/constants/apiconstants";
import { debounce } from "lodash-es";
import GridActionsRenderer from "@/grid/GridActionsRenderer.vue";
import { onlyParentOrEmployerSelected } from "@/utils/EmployerSelectorUtils";
import { parseErrorMessage } from "@/utils/ErrorUtils";
import { EmployerHierarchy } from "@/store/modules/persistent/persistentTypes";
import FieldGroup from "@/form/FieldGroup.vue";
import WizardFormStep from "@/components/WizardFormStep.vue";
import TextField from "@/form/TextField.vue";
import { VForm } from "@/@typings/type-vee-validate";
import SelectField from "@/form/SelectField.vue";
import { SelectOption } from "@/form/FieldOptions";
import { columnDateTimeFormatter } from "@/utils/CommonUtils";
import DropdownMenu from "@/components/DropdownMenu.vue";
import DropdownMenuItem from "@/components/DropdownMenuItem.vue";
import { hasPermission } from "@/utils/PermissionUtils";

interface DefinedBenefitReport {
	id: number;
	type: string;
	employeeLevel: string;
	fileName: string; //not sent by the api - always null
	createdDate: Date;
	statusDate: Date; //not sent by the api - always null
	status: string;
	userId: number;
	employerId: number;
	actionedBy: string; //not sent by the api - always null
	jobRef: string;
	deletedDate: Date;
	correlationId: string; //not sent by the api - always null
}

interface XapiParamField {
	field: string;
	label: string;
	type: "TextField" | "DatepickerField";
	value: string;
	rules?: string;
	// set only by our frontend
	name?: string;
}

const { mapState } = createNamespacedHelpers("persistent");

@Component({
	components: {
		DropdownMenuItem,
		DropdownMenu,
		SelectField,
		TextField,
		WizardFormStep,
		FieldGroup,
		AutoField,
		Modal,
		Button,
		EmployerSelector,
		Grid,
		PageHeader,
		Layout,
		ReportTable,
		GridFilter,
		DatepickerField,
		Form,
		LeftRightFooter,
	},
	computed: mapState([
		"selectedEntities",
		"employerHierarchy",
		"definedBenefitEntities",
	]),
	methods: {
		hasPermission,
		...mapMutations(["setSelectedEntities"]),
	},
})
export default class DefinedBenefitReportPage
	extends Vue
	implements IServerSideDatasourceTyped
{
	private reportsInProcessing = false;
	definedBenefitEntities!: string[];

	getRows(params: IServerSideGetRowsParamsTyped<DefinedBenefitReport>): void {
		params.request.filterModel = Object.keys(this.filterModel).map(
			(key) => {
				return this.filterModel[key];
			}
		);

		axios
			.get<PagedResult<DefinedBenefitReport>>(
				definedBenefitReport() + "/list",
				{
					params: {
						grid: params.request,
						entities: this.$store.state.persistent.selectedEntities,
					},
					cancelToken: params.cancelToken,
				}
			)
			.then((response) => {
				// This will start polling if someone loads the page and there are reports that haven't completed. Probably don't want this behaviour
				params.successCallback(response.data);
				const stillProcessingReports = response.data.elements.filter(
					(db) => db.status === "INIT"
				);
				this.reportsInProcessing = stillProcessingReports.length > 0;
				if (this.reportsInProcessing) {
					this.setIntervalForNewId("dbReportGrid");
				} else {
					this.stopRefreshTimer();
				}
			})
			.catch((error) => {
				if (axiosStatic.isCancel(error)) {
					return;
				}
				toastErrorMessage(parseErrorMessage(error));
			});

		params.successCallback([]);
	}

	private readonly vuexStore = "definedBenefitReportPage";
	private gridReady = false;

	private static readonly DATA_REFRESH_INTERVAL: number = 10;
	private readonly multiSelect = false;
	private errorMessage: string | null = null;
	selectedEntities!: string[];
	employerHierarchy!: EmployerHierarchy[];

	private showReportModal = false;

	private formData = {};

	private reportTypes: SelectOption[] = [];

	// Since our backend uses an enum for report types we can't allow unhandled report types from showing or to be used.
	private readonly portalAllowedReportTypes = [
		{
			label: "Variance Report",
			value: "varianceReport",
		},
		{
			label: "Contribution Report",
			value: "contributionReport",
		},
		{
			label: "Error Scenario Report",
			value: "errorScenarioReport",
		},
	];

	private selectedReportType = "";

	private readonly employeeLevels: SelectOption[] = [
		{
			value: "S",
			label: "Single",
		},
		{
			value: "A",
			label: "All",
		},
	];

	private readonly reportStatuses: SelectOption[] = [
		{
			value: "INIT",
			label: "In Progress",
		},
		{
			value: "DONE",
			label: "Completed",
		},
		{
			value: "ERR",
			label: "Error",
		},
	];

	private selectedEmployeeLevel = "";

	private get reportModalTitle() {
		return `Create ${
			this.reportTypes.find((r) => r.value === this.selectedReportType)
				?.label ?? "report"
		}`;
	}

	private reportParamsMap: Map<string, XapiParamField> = new Map<
		string,
		XapiParamField
	>();

	private loadingParams = false;
	readonly actionColumn: ColDef = {
		headerName: "Actions",
		field: "__Actions",
		cellRenderer: this.actionsRender,
		resizable: true,
		pinned: "right",
		minWidth: 80,
		maxWidth: 100,
	};

	public $refs!: {
		gridEl: Grid;
		dbReportForm: VForm;
	};

	private filterModel: FilterModel = {
		status: {
			value: "",
			column: "status",
		},
		type: {
			value: "",
			column: "type",
		},
		createdDate: {
			value: "",
			column: "createdDate",
		},
		employeeLevel: {
			value: "",
			column: "employeeLevel",
		},
	};
	private readonly columnDefs: (ColGroupDef | ColDef)[] = [
		{
			headerName: "Report ID",
			field: "id",
			minWidth: 100,
		},
		{
			headerName: "Type",
			field: "type",
			resizable: true,
			valueFormatter: this.typeFormatter,
			minWidth: 100,
		},
		{
			headerName: "Employee level",
			field: "employeeLevel",
			resizable: true,
			valueFormatter: this.employeeLevelFormatter,
			minWidth: 100,
		},
		{
			headerName: "Status",
			field: "status",
			resizable: true,
			valueFormatter: this.statusFormatter,
			minWidth: 160,
		},
		{
			headerName: "Actioned by",
			field: "actionedBy",
			resizable: true,
			minWidth: 160,
		},
		{
			headerName: "Created date",
			field: "createdDate",
			resizable: true,
			valueFormatter: columnDateTimeFormatter,
			minWidth: 160,
		},
		{
			headerName: "Status date",
			field: "statusDate",
			resizable: true,
			valueFormatter: columnDateTimeFormatter,
			minWidth: 160,
		},
		this.actionColumn,
	];

	private statusFormatter(params: ValueFormatterParams): string {
		const value = params.value;
		const status = this.reportStatuses.find(
			(status) => status.value === value
		);
		return status?.label ?? "";
	}

	private employeeLevelFormatter(params: ValueFormatterParams): string {
		const value = params.value;
		const level = this.employeeLevels.find(
			(level) => level.value === value
		);
		return level?.label ?? "";
	}

	private typeFormatter(params: ValueFormatterParams): string {
		const value = params.value;

		// Report type labels might take longer to load then the actual grid.
		// This will insert a placeholder labels for them just in case
		const type =
			this.reportTypes.find((type) => type.value === value) ??
			this.portalAllowedReportTypes.find((type) => type.value === value);

		return type?.label.replace(" Report", "") ?? "";
	}

	private readonly intervalData: {
		id?: string;
		remaining?: number;
		intervalRef?: number;
		isRefreshing: boolean;
	} = {
		id: undefined,
		remaining: undefined,
		intervalRef: undefined,
		isRefreshing: false,
	};

	private triggerRefresh() {
		this.intervalData.isRefreshing = true;
		this.reloadGrid();
	}

	private onGridReady() {
		this.gridReady = true;
	}

	/**
	 * Summary data is refreshed periodically while the page is open
	 */
	private setIntervalForNewId(id: string) {
		if (this.intervalData.intervalRef !== undefined) {
			this.intervalData.isRefreshing = false;
			clearInterval(this.intervalData.intervalRef);
			this.intervalData.intervalRef = undefined;
		}

		this.intervalData.id = id;
		this.intervalData.remaining =
			DefinedBenefitReportPage.DATA_REFRESH_INTERVAL;
		// Runs every second and triggers refresh every DATA_REFRESH_INTERVAL
		this.intervalData.intervalRef = window.setInterval(() => {
			if (this.intervalData.isRefreshing) {
				return;
			}
			if (
				this.intervalData.remaining === undefined ||
				this.intervalData.remaining < 0
			) {
				throw new Error("Invalid state for refresh interval");
			}

			if (this.intervalData.remaining === 0 && this.canRefresh) {
				return;
			}
			this.intervalData.remaining = Math.abs(
				this.intervalData.remaining - 1
			);
		}, 1000);
	}

	stopRefreshTimer(): void {
		if (this.intervalData.intervalRef !== undefined) {
			clearInterval(this.intervalData.intervalRef);
			this.intervalData.intervalRef = undefined;
		}
		this.intervalData.remaining = undefined;
	}

	get refreshCount(): string {
		if (this.intervalData.remaining === undefined) {
			return "";
		}
		return this.intervalData.remaining === 0
			? ""
			: "" + this.intervalData.remaining;
	}

	private readonly onClickRefresh = debounce(
		() => {
			this.triggerRefresh();
			this.setIntervalForNewId("dbReportGrid");
		},
		500,
		{ leading: true, trailing: false }
	);

	/**
	 * Fetches the "create report" params for when the employer changes. Will need to be shifted to when the fund changes instead.
	 * We don't have a fund selector yet.
	 */
	@Watch("selectedReportingCenter")
	onSelectedReportingCenterChanged() {
		this.fetchAvailableReportTypes();
		this.reloadGrid();
	}

	private fetchAvailableReportTypes() {
		if (
			this.selectedEntities.length !== 0 &&
			this.definedBenefitEntities.includes(this.selectedEntities[0])
		) {
			axios
				.get<SelectOption[]>(
					definedBenefitReport() + "/available-report-types",
					{ params: { entities: this.selectedEntities } }
				)
				.then((resp) => {
					this.reportTypes = resp.data.filter(
						(type) =>
							this.portalAllowedReportTypes.filter(
								(allowedType) =>
									allowedType.value === type.value
							).length !== 0
					);
				})
				.catch((error) => {
					toastErrorMessage(parseErrorMessage(error));
				});
		} else {
			this.reportTypes = [];
		}
	}

	private fetchReportParams() {
		if (this.selectedReportType === "") {
			return;
		}

		this.loadingParams = true;

		axios
			.get<XapiParamField[]>(
				definedBenefitReport() + `/${this.selectedReportType}/params`,
				{ params: { entities: this.selectedEntities } }
			)
			.then((resp) => {
				this.reportParamsMap = new Map(Object.entries(resp.data));
				this.loadingParams = false;
			})
			.catch((error) => {
				toastErrorMessage(parseErrorMessage(error));
				this.loadingParams = false;
			});
	}

	get selectedReportingCenter() {
		return this.$store.state.persistent.selectedEntities;
	}

	mounted() {
		registerModule(this.$store, this.vuexStore, this.filterModel);
		this.filterModel = this.$store.getters[`${this.vuexStore}/filters`];
		this.fetchAvailableReportTypes();
	}

	private onRowClickView(row: {
		rowIndex: number;
		row: DefinedBenefitReport;
	}): void {
		const empId = row.row.employerId.toString();
		const repId = row.row.id.toString();
		this.$router.push({
			name: "Defined Benefit Report View",
			params: {
				empId,
				repId,
			},
		});
	}

	actionsRender(
		params: ICellRendererParamsTyped<DefinedBenefitReport>
	): HTMLElement {
		const vm = new Vue({
			el: document.createElement("div"),
			render: (createElement) => {
				return createElement(GridActionsRenderer, {
					props: {
						rowIndex: params.rowIndex,
						row: params.data,
						isEdit: false,
						isDelete: false,
						isView:
							params.data.status === "DONE" &&
							hasPermission("VIEW_DB_REPORT"),
					},
					on: {
						clickView: this.onRowClickView,
					},
				});
			},
		});
		return vm.$el as HTMLElement;
	}

	clearErrorMessage() {
		this.errorMessage = null;
	}

	private reloadGrid(): void {
		this.gridReady = false;
		if (!this.$refs.gridEl) {
			return;
		}
		this.$refs.gridEl.reload();
		this.gridReady = true;
		this.intervalData.isRefreshing = false;
	}

	onApplyFilter() {
		commitToModule(this.$store, this.vuexStore, this.filterModel);
		this.$refs.gridEl.reload();
	}

	onResetFilter() {
		this.filterModel.createdDate.value = "";
		this.filterModel.type.value = "";
		this.filterModel.employeeLevel.value = "";
		this.filterModel.status.value = "";
		this.onApplyFilter();
	}

	openReportModal(item: string) {
		if (onlyParentOrEmployerSelected(this.selectedEntities, true)) {
			this.selectedReportType = item;
			this.fetchReportParams();
			this.showReportModal = true;
		} else {
			this.errorMessage =
				"An Employer must be selected in order to generate a report";
		}
	}

	closeReportModal() {
		this.showReportModal = false;
		this.selectedReportType = "";
		this.formData = {};
		this.selectedEmployeeLevel = "";
		this.reportParamsMap = new Map<string, XapiParamField>();
	}

	onSubmitReport() {
		const value = this.$store.state.persistent.selectedEntities;
		axios
			.post(
				definedBenefitReport() + `/${this.selectedReportType}`,
				{
					payload: this.formData,
					entities: value,
					employeeLevel: this.selectedEmployeeLevel,
				},
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			)

			.then(() => {
				this.reloadGrid();
				this.setIntervalForNewId("dbReportGrid");
				toastInfoMessage("Creating report, please wait.");
			})
			.catch(() =>
				toastErrorMessage(
					"Error occurred when attempting to generate report"
				)
			);
		this.closeReportModal();
		this.formData = {};
		this.selectedEmployeeLevel = "";
	}

	get canCreateReport() {
		return (
			this.selectedReportingCenter.length > 0 &&
			this.reportTypes.length > 0
		);
	}

	get canRefresh(): boolean {
		return this.reportsInProcessing;
	}
}
